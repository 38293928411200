import { useNavigate } from "react-router-dom";

import PictureTextBlock from "../../components/picture-text-block";
import AdvantagesCard from "../../components/advantages-card";
import Banner from "../../components/banner";
import UnderBannerContainer from "../../components/under-banner-container";
import Stepped from "../../components/stepped";
import Map from "../../components/map";

import Rocket from "../../components/icons/fle-rocket";
import EuroBanknote from "../../components/icons/fle-euro-banknote";
import OkHand from "../../components/icons/fle-ok-hand";
import Shield from "../../components/icons/fle-shield";

import classes from "./index.module.scss";

const { landingAdvantages } = classes;

export default function Lahding() {
   const navigate = useNavigate();
   const advantages = [
      {
         Icon: Rocket,
         title: "Быстрые переводы",
         description: "Переводы из России<br />в Черногорию в пару кликов",
      },
      {
         Icon: EuroBanknote,
         title: "Доставка до&nbsp;двери",
         description: "Бесплатная доставка наличных<br />в Будве и Бечичи",
      },
      {
         Icon: OkHand,
         title: "Без комиссии",
         description: "Переводы без комиссий<br />и скрытых платежей",
      },
      {
         Icon: Shield,
         title: "Под защитой",
         description: "Ваши персональные данные надёжно зашифрованы",
      },
   ];
   const steps = [
      {
         point: "1",
         content: "Оставляете заявку на денежный перевод",
      },
      {
         point: "2",
         content: "Наш сотрудник встретиться с Вами",
      },
      {
         point: "3",
         content: "Переводите деньги на наш счёт в РФ",
      },
      {
         point: "4",
         content: "Наш сотрудник выдаёт вам наличные в евро",
      },
   ];

   return (
      <>
         <Banner img="main-banner" alt={"Девушка в красном педжаке, в очках сердечках смотрит через банкноты."} />
         <UnderBannerContainer className={landingAdvantages}>
            {advantages.map((adv, i) => (
               <AdvantagesCard Icon={adv.Icon} title={adv.title} description={adv.description} key={i} />
            ))}
         </UnderBannerContainer>
         <PictureTextBlock
            title={"Переводы"}
            text={[
               "В MooPay вы можете легко и быстро перевести деньги со счёта в Российском банке в наличные евро в Черногории.",
               "Это также просто, как перевести деньги из одного Российского банка в другой.",
            ]}
            pictureName={"atm"}
            pictureAlt={"Человек вставляет банковскую карточку в банкоман."}
         />
         <Stepped title={"Как происходит перевод?"} steps={steps} />
         <PictureTextBlock
            title={"Как оставить заявку?"}
            text={["В этом нет ничего сложного! Для подачи заявки Вам потребуется 5 минут и Ваш заграничный паспорт."]}
            pictureName={"women"}
            pictureAlt={"Женщина средних лет стоит за столом на кухне и смотрит в экран ноутбука."}
            ltr={true}
            isButton={true}
            buttonAction={() => navigate("/transfer")}
         />
         <Map />
      </>
   );
}
