export default function Rocket(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "64"}
         height={props.height ? props.height : "64"}
         viewBox="0 0 64 64"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M21.164 27.436C23.564 26.552 25.898 28.886 25.014 31.288L19.78 45.516C19.6642 45.8312 19.4709 46.1122 19.2178 46.333C18.9648 46.5539 18.6603 46.7074 18.3323 46.7796C18.0043 46.8517 17.6634 46.8401 17.3411 46.7458C17.0188 46.6515 16.7254 46.4775 16.488 46.24L6.212 35.964C5.97492 35.7266 5.80137 35.4333 5.70734 35.1113C5.61331 34.7892 5.60182 34.4486 5.67393 34.1209C5.74604 33.7932 5.89944 33.489 6.11998 33.2361C6.34053 32.9833 6.62115 32.79 6.936 32.674L21.164 27.434V27.436Z"
            fill="url(#paint0_linear_120_61)"
         />
         <path
            d="M21.164 27.436C23.564 26.552 25.898 28.886 25.014 31.288L19.78 45.516C19.6642 45.8312 19.4709 46.1122 19.2178 46.333C18.9648 46.5539 18.6603 46.7074 18.3323 46.7796C18.0043 46.8517 17.6634 46.8401 17.3411 46.7458C17.0188 46.6515 16.7254 46.4775 16.488 46.24L6.212 35.964C5.97492 35.7266 5.80137 35.4333 5.70734 35.1113C5.61331 34.7892 5.60182 34.4486 5.67393 34.1209C5.74604 33.7932 5.89944 33.489 6.11998 33.2361C6.34053 32.9833 6.62115 32.79 6.936 32.674L21.164 27.434V27.436Z"
            fill="url(#paint1_radial_120_61)"
         />
         <path
            d="M21.164 27.436C23.564 26.552 25.898 28.886 25.014 31.288L19.78 45.516C19.6642 45.8312 19.4709 46.1122 19.2178 46.333C18.9648 46.5539 18.6603 46.7074 18.3323 46.7796C18.0043 46.8517 17.6634 46.8401 17.3411 46.7458C17.0188 46.6515 16.7254 46.4775 16.488 46.24L6.212 35.964C5.97492 35.7266 5.80137 35.4333 5.70734 35.1113C5.61331 34.7892 5.60182 34.4486 5.67393 34.1209C5.74604 33.7932 5.89944 33.489 6.11998 33.2361C6.34053 32.9833 6.62115 32.79 6.936 32.674L21.164 27.434V27.436Z"
            fill="url(#paint2_linear_120_61)"
         />
         <path
            d="M33.1621 39.436C35.5641 38.552 37.8981 40.886 37.0141 43.288L31.7761 57.516C31.6601 57.8308 31.4668 58.1115 31.2139 58.332C30.9611 58.5526 30.6568 58.706 30.3291 58.7781C30.0014 58.8502 29.6609 58.8387 29.3388 58.7447C29.0167 58.6506 28.7235 58.4771 28.4861 58.24L18.2101 47.964C17.973 47.7266 17.7994 47.4333 17.7054 47.1113C17.6114 46.7892 17.5999 46.4486 17.672 46.1209C17.7441 45.7932 17.8975 45.489 18.118 45.2361C18.3386 44.9833 18.6192 44.79 18.9341 44.674L33.1621 39.434V39.436Z"
            fill="url(#paint3_linear_120_61)"
         />
         <path
            d="M33.1621 39.436C35.5641 38.552 37.8981 40.886 37.0141 43.288L31.7761 57.516C31.6601 57.8308 31.4668 58.1115 31.2139 58.332C30.9611 58.5526 30.6568 58.706 30.3291 58.7781C30.0014 58.8502 29.6609 58.8387 29.3388 58.7447C29.0167 58.6506 28.7235 58.4771 28.4861 58.24L18.2101 47.964C17.973 47.7266 17.7994 47.4333 17.7054 47.1113C17.6114 46.7892 17.5999 46.4486 17.672 46.1209C17.7441 45.7932 17.8975 45.489 18.118 45.2361C18.3386 44.9833 18.6192 44.79 18.9341 44.674L33.1621 39.434V39.436Z"
            fill="url(#paint4_radial_120_61)"
         />
         <path
            d="M33.1621 39.436C35.5641 38.552 37.8981 40.886 37.0141 43.288L31.7761 57.516C31.6601 57.8308 31.4668 58.1115 31.2139 58.332C30.9611 58.5526 30.6568 58.706 30.3291 58.7781C30.0014 58.8502 29.6609 58.8387 29.3388 58.7447C29.0167 58.6506 28.7235 58.4771 28.4861 58.24L18.2101 47.964C17.973 47.7266 17.7994 47.4333 17.7054 47.1113C17.6114 46.7892 17.5999 46.4486 17.672 46.1209C17.7441 45.7932 17.8975 45.489 18.118 45.2361C18.3386 44.9833 18.6192 44.79 18.9341 44.674L33.1621 39.434V39.436Z"
            fill="url(#paint5_radial_120_61)"
         />
         <g filter="url(#filter0_i_120_61)">
            <path
               d="M44.7999 7.09999C44.8547 7.07829 44.9146 7.07316 44.9722 7.08525C45.0298 7.09735 45.0826 7.12612 45.1239 7.16799L56.8939 19.028C56.9355 19.0696 56.9639 19.1225 56.9756 19.1801C56.9873 19.2377 56.9819 19.2975 56.9599 19.352C54.1399 26.272 50.2539 33.632 45.0519 38.836C39.9839 43.904 31.4119 48.222 23.6719 51.398L12.6899 40.414C15.8659 32.674 20.1839 24.104 25.2519 19.036C30.4719 13.816 37.8599 9.92199 44.7979 7.10199L44.7999 7.09999Z"
               fill="url(#paint6_linear_120_61)"
            />
            <path
               d="M44.7999 7.09999C44.8547 7.07829 44.9146 7.07316 44.9722 7.08525C45.0298 7.09735 45.0826 7.12612 45.1239 7.16799L56.8939 19.028C56.9355 19.0696 56.9639 19.1225 56.9756 19.1801C56.9873 19.2377 56.9819 19.2975 56.9599 19.352C54.1399 26.272 50.2539 33.632 45.0519 38.836C39.9839 43.904 31.4119 48.222 23.6719 51.398L12.6899 40.414C15.8659 32.674 20.1839 24.104 25.2519 19.036C30.4719 13.816 37.8599 9.92199 44.7979 7.10199L44.7999 7.09999Z"
               fill="url(#paint7_radial_120_61)"
            />
            <path
               d="M44.7999 7.09999C44.8547 7.07829 44.9146 7.07316 44.9722 7.08525C45.0298 7.09735 45.0826 7.12612 45.1239 7.16799L56.8939 19.028C56.9355 19.0696 56.9639 19.1225 56.9756 19.1801C56.9873 19.2377 56.9819 19.2975 56.9599 19.352C54.1399 26.272 50.2539 33.632 45.0519 38.836C39.9839 43.904 31.4119 48.222 23.6719 51.398L12.6899 40.414C15.8659 32.674 20.1839 24.104 25.2519 19.036C30.4719 13.816 37.8599 9.92199 44.7979 7.10199L44.7999 7.09999Z"
               fill="url(#paint8_radial_120_61)"
            />
            <path
               d="M44.7999 7.09999C44.8547 7.07829 44.9146 7.07316 44.9722 7.08525C45.0298 7.09735 45.0826 7.12612 45.1239 7.16799L56.8939 19.028C56.9355 19.0696 56.9639 19.1225 56.9756 19.1801C56.9873 19.2377 56.9819 19.2975 56.9599 19.352C54.1399 26.272 50.2539 33.632 45.0519 38.836C39.9839 43.904 31.4119 48.222 23.6719 51.398L12.6899 40.414C15.8659 32.674 20.1839 24.104 25.2519 19.036C30.4719 13.816 37.8599 9.92199 44.7979 7.10199L44.7999 7.09999Z"
               fill="url(#paint9_radial_120_61)"
            />
            <path
               d="M44.7999 7.09999C44.8547 7.07829 44.9146 7.07316 44.9722 7.08525C45.0298 7.09735 45.0826 7.12612 45.1239 7.16799L56.8939 19.028C56.9355 19.0696 56.9639 19.1225 56.9756 19.1801C56.9873 19.2377 56.9819 19.2975 56.9599 19.352C54.1399 26.272 50.2539 33.632 45.0519 38.836C39.9839 43.904 31.4119 48.222 23.6719 51.398L12.6899 40.414C15.8659 32.674 20.1839 24.104 25.2519 19.036C30.4719 13.816 37.8599 9.92199 44.7979 7.10199L44.7999 7.09999Z"
               fill="url(#paint10_radial_120_61)"
            />
         </g>
         <g filter="url(#filter1_iii_120_61)">
            <path
               d="M49 22.124C49.0154 23.0161 48.853 23.9023 48.5223 24.731C48.1915 25.5597 47.699 26.3142 47.0736 26.9505C46.4482 27.5868 45.7023 28.0922 44.8794 28.4372C44.0566 28.7822 43.1733 28.9599 42.281 28.9599C41.3888 28.9599 40.5055 28.7822 39.6826 28.4372C38.8598 28.0922 38.1139 27.5868 37.4885 26.9505C36.863 26.3142 36.3706 25.5597 36.0398 24.731C35.7091 23.9023 35.5466 23.0161 35.562 22.124C35.5924 20.3621 36.3137 18.6826 37.5704 17.4473C38.8272 16.2121 40.5189 15.5199 42.281 15.5199C44.0432 15.5199 45.7349 16.2121 46.9916 17.4473C48.2484 18.6826 48.9696 20.3621 49 22.124V22.124Z"
               fill="url(#paint11_linear_120_61)"
            />
            <path
               d="M49 22.124C49.0154 23.0161 48.853 23.9023 48.5223 24.731C48.1915 25.5597 47.699 26.3142 47.0736 26.9505C46.4482 27.5868 45.7023 28.0922 44.8794 28.4372C44.0566 28.7822 43.1733 28.9599 42.281 28.9599C41.3888 28.9599 40.5055 28.7822 39.6826 28.4372C38.8598 28.0922 38.1139 27.5868 37.4885 26.9505C36.863 26.3142 36.3706 25.5597 36.0398 24.731C35.7091 23.9023 35.5466 23.0161 35.562 22.124C35.5924 20.3621 36.3137 18.6826 37.5704 17.4473C38.8272 16.2121 40.5189 15.5199 42.281 15.5199C44.0432 15.5199 45.7349 16.2121 46.9916 17.4473C48.2484 18.6826 48.9696 20.3621 49 22.124V22.124Z"
               fill="url(#paint12_radial_120_61)"
            />
         </g>
         <path
            d="M46.932 22.124C46.9449 22.7429 46.834 23.3582 46.6061 23.9337C46.3781 24.5092 46.0376 25.0334 45.6044 25.4757C45.1713 25.9179 44.6542 26.2692 44.0835 26.5091C43.5129 26.7489 42.9001 26.8725 42.281 26.8725C41.662 26.8725 41.0492 26.7489 40.4785 26.5091C39.9079 26.2692 39.3908 25.9179 38.9576 25.4757C38.5245 25.0334 38.1839 24.5092 37.956 23.9337C37.728 23.3582 37.6172 22.7429 37.63 22.124C37.6553 20.9072 38.1564 19.7487 39.0258 18.8971C39.8953 18.0455 41.0639 17.5685 42.281 17.5685C43.4981 17.5685 44.6667 18.0455 45.5362 18.8971C46.4057 19.7487 46.9068 20.9072 46.932 22.124V22.124Z"
            fill="url(#paint13_radial_120_61)"
         />
         <g filter="url(#filter2_i_120_61)">
            <path
               d="M4.846 59.066C3.432 57.652 4 47.844 9 45.844C9 45.844 14 43.844 17.222 47.044C20.444 50.244 19 53.844 19 53.844C17.586 58.086 11.566 59.772 10.858 59.064C10.468 58.674 11.17 57.964 10.858 57.652C10.546 57.338 10.062 57.696 8.738 58.358C7.794 58.83 5.412 59.632 4.846 59.066Z"
               fill="url(#paint14_radial_120_61)"
            />
            <path
               d="M4.846 59.066C3.432 57.652 4 47.844 9 45.844C9 45.844 14 43.844 17.222 47.044C20.444 50.244 19 53.844 19 53.844C17.586 58.086 11.566 59.772 10.858 59.064C10.468 58.674 11.17 57.964 10.858 57.652C10.546 57.338 10.062 57.696 8.738 58.358C7.794 58.83 5.412 59.632 4.846 59.066Z"
               fill="url(#paint15_radial_120_61)"
            />
         </g>
         <path
            d="M12.12 41.964C12.3986 41.6852 12.7294 41.464 13.0935 41.3131C13.4576 41.1623 13.8478 41.0846 14.242 41.0846C14.6361 41.0846 15.0264 41.1623 15.3905 41.3131C15.7546 41.464 16.0854 41.6852 16.364 41.964L23.434 49.036C23.9709 49.6034 24.2654 50.3579 24.2547 51.139C24.244 51.9201 23.929 52.6663 23.3768 53.2188C22.8245 53.7713 22.0785 54.0866 21.2974 54.0977C20.5163 54.1087 19.7616 53.8146 19.194 53.278L12.12 46.208C11.8412 45.9294 11.62 45.5985 11.4691 45.2344C11.3183 44.8703 11.2406 44.4801 11.2406 44.086C11.2406 43.6918 11.3183 43.3016 11.4691 42.9375C11.62 42.5734 11.8412 42.2426 12.12 41.964V41.964Z"
            fill="url(#paint16_linear_120_61)"
         />
         <path
            d="M12.12 41.964C12.3986 41.6852 12.7294 41.464 13.0935 41.3131C13.4576 41.1623 13.8478 41.0846 14.242 41.0846C14.6361 41.0846 15.0264 41.1623 15.3905 41.3131C15.7546 41.464 16.0854 41.6852 16.364 41.964L23.434 49.036C23.9709 49.6034 24.2654 50.3579 24.2547 51.139C24.244 51.9201 23.929 52.6663 23.3768 53.2188C22.8245 53.7713 22.0785 54.0866 21.2974 54.0977C20.5163 54.1087 19.7616 53.8146 19.194 53.278L12.12 46.208C11.8412 45.9294 11.62 45.5985 11.4691 45.2344C11.3183 44.8703 11.2406 44.4801 11.2406 44.086C11.2406 43.6918 11.3183 43.3016 11.4691 42.9375C11.62 42.5734 11.8412 42.2426 12.12 41.964V41.964Z"
            fill="url(#paint17_radial_120_61)"
         />
         <path
            d="M12.12 41.964C12.3986 41.6852 12.7294 41.464 13.0935 41.3131C13.4576 41.1623 13.8478 41.0846 14.242 41.0846C14.6361 41.0846 15.0264 41.1623 15.3905 41.3131C15.7546 41.464 16.0854 41.6852 16.364 41.964L23.434 49.036C23.9709 49.6034 24.2654 50.3579 24.2547 51.139C24.244 51.9201 23.929 52.6663 23.3768 53.2188C22.8245 53.7713 22.0785 54.0866 21.2974 54.0977C20.5163 54.1087 19.7616 53.8146 19.194 53.278L12.12 46.208C11.8412 45.9294 11.62 45.5985 11.4691 45.2344C11.3183 44.8703 11.2406 44.4801 11.2406 44.086C11.2406 43.6918 11.3183 43.3016 11.4691 42.9375C11.62 42.5734 11.8412 42.2426 12.12 41.964V41.964Z"
            fill="url(#paint18_radial_120_61)"
         />
         <path
            d="M58.344 5.742C56.45 3.848 53.128 4.062 50.708 4.894C48.882 5.51971 47.0721 6.19126 45.28 6.908C45.2494 6.92027 45.2223 6.9399 45.2011 6.96516C45.18 6.99042 45.1654 7.02052 45.1586 7.05278C45.1519 7.08504 45.1533 7.11847 45.1626 7.15008C45.1719 7.1817 45.1888 7.21052 45.212 7.234L56.832 18.932C56.8553 18.9547 56.8839 18.9714 56.9151 18.9806C56.9464 18.9898 56.9795 18.9912 57.0114 18.9847C57.0434 18.9782 57.0732 18.964 57.0984 18.9433C57.1236 18.9226 57.1434 18.8961 57.156 18.866C57.8817 17.0549 58.5612 15.2256 59.194 13.38C60.014 10.992 60.674 8.072 58.344 5.742V5.742Z"
            fill="url(#paint19_linear_120_61)"
         />
         <path
            d="M58.344 5.742C56.45 3.848 53.128 4.062 50.708 4.894C48.882 5.51971 47.0721 6.19126 45.28 6.908C45.2494 6.92027 45.2223 6.9399 45.2011 6.96516C45.18 6.99042 45.1654 7.02052 45.1586 7.05278C45.1519 7.08504 45.1533 7.11847 45.1626 7.15008C45.1719 7.1817 45.1888 7.21052 45.212 7.234L56.832 18.932C56.8553 18.9547 56.8839 18.9714 56.9151 18.9806C56.9464 18.9898 56.9795 18.9912 57.0114 18.9847C57.0434 18.9782 57.0732 18.964 57.0984 18.9433C57.1236 18.9226 57.1434 18.8961 57.156 18.866C57.8817 17.0549 58.5612 15.2256 59.194 13.38C60.014 10.992 60.674 8.072 58.344 5.742V5.742Z"
            fill="url(#paint20_radial_120_61)"
         />
         <path
            d="M58.344 5.742C56.45 3.848 53.128 4.062 50.708 4.894C48.882 5.51971 47.0721 6.19126 45.28 6.908C45.2494 6.92027 45.2223 6.9399 45.2011 6.96516C45.18 6.99042 45.1654 7.02052 45.1586 7.05278C45.1519 7.08504 45.1533 7.11847 45.1626 7.15008C45.1719 7.1817 45.1888 7.21052 45.212 7.234L56.832 18.932C56.8553 18.9547 56.8839 18.9714 56.9151 18.9806C56.9464 18.9898 56.9795 18.9912 57.0114 18.9847C57.0434 18.9782 57.0732 18.964 57.0984 18.9433C57.1236 18.9226 57.1434 18.8961 57.156 18.866C57.8817 17.0549 58.5612 15.2256 59.194 13.38C60.014 10.992 60.674 8.072 58.344 5.742V5.742Z"
            fill="url(#paint21_linear_120_61)"
         />
         <path
            d="M27.1641 33.436C29.5641 32.552 31.8981 34.886 31.0141 37.288L25.7781 51.516C25.6622 51.8312 25.4689 52.1122 25.2159 52.333C24.9629 52.5539 24.6583 52.7074 24.3303 52.7796C24.0023 52.8517 23.6615 52.8401 23.3391 52.7458C23.0168 52.6515 22.7234 52.4775 22.4861 52.24L12.2101 41.964C11.973 41.7266 11.7994 41.4333 11.7054 41.1113C11.6114 40.7892 11.5999 40.4486 11.672 40.1209C11.7441 39.7932 11.8975 39.489 12.118 39.2361C12.3386 38.9833 12.6192 38.79 12.9341 38.674L27.1641 33.434V33.436Z"
            fill="url(#paint22_linear_120_61)"
         />
         <path
            d="M27.1641 33.436C29.5641 32.552 31.8981 34.886 31.0141 37.288L25.7781 51.516C25.6622 51.8312 25.4689 52.1122 25.2159 52.333C24.9629 52.5539 24.6583 52.7074 24.3303 52.7796C24.0023 52.8517 23.6615 52.8401 23.3391 52.7458C23.0168 52.6515 22.7234 52.4775 22.4861 52.24L12.2101 41.964C11.973 41.7266 11.7994 41.4333 11.7054 41.1113C11.6114 40.7892 11.5999 40.4486 11.672 40.1209C11.7441 39.7932 11.8975 39.489 12.118 39.2361C12.3386 38.9833 12.6192 38.79 12.9341 38.674L27.1641 33.434V33.436Z"
            fill="url(#paint23_radial_120_61)"
         />
         <path
            d="M27.1641 33.436C29.5641 32.552 31.8981 34.886 31.0141 37.288L25.7781 51.516C25.6622 51.8312 25.4689 52.1122 25.2159 52.333C24.9629 52.5539 24.6583 52.7074 24.3303 52.7796C24.0023 52.8517 23.6615 52.8401 23.3391 52.7458C23.0168 52.6515 22.7234 52.4775 22.4861 52.24L12.2101 41.964C11.973 41.7266 11.7994 41.4333 11.7054 41.1113C11.6114 40.7892 11.5999 40.4486 11.672 40.1209C11.7441 39.7932 11.8975 39.489 12.118 39.2361C12.3386 38.9833 12.6192 38.79 12.9341 38.674L27.1641 33.434V33.436Z"
            fill="url(#paint24_radial_120_61)"
         />
         <path
            d="M27.1641 33.436C29.5641 32.552 31.8981 34.886 31.0141 37.288L25.7781 51.516C25.6622 51.8312 25.4689 52.1122 25.2159 52.333C24.9629 52.5539 24.6583 52.7074 24.3303 52.7796C24.0023 52.8517 23.6615 52.8401 23.3391 52.7458C23.0168 52.6515 22.7234 52.4775 22.4861 52.24L12.2101 41.964C11.973 41.7266 11.7994 41.4333 11.7054 41.1113C11.6114 40.7892 11.5999 40.4486 11.672 40.1209C11.7441 39.7932 11.8975 39.489 12.118 39.2361C12.3386 38.9833 12.6192 38.79 12.9341 38.674L27.1641 33.434V33.436Z"
            fill="url(#paint25_radial_120_61)"
         />
         <g filter="url(#filter3_f_120_61)">
            <path d="M18.41 44.094L29.66 35.28L22.41 48.156L18.41 44.094Z" fill="url(#paint26_linear_120_61)" />
         </g>
         <defs>
            <filter
               id="filter0_i_120_61"
               x="12.4399"
               y="6.57886"
               width="44.5417"
               height="44.8191"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="-0.25" dy="-0.5" />
               <feGaussianBlur stdDeviation="0.75" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.745098 0 0 0 0 0.772549 0 0 0 0 0.952941 0 0 0 1 0" />
               <feBlend mode="normal" in2="shape" result="effect1_innerShadow_120_61" />
            </filter>
            <filter
               id="filter1_iii_120_61"
               x="35.461"
               y="15.4199"
               width="13.6399"
               height="13.7899"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dy="0.25" />
               <feGaussianBlur stdDeviation="0.5" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.866667 0 0 0 0 0.764706 0 0 0 0 0.847059 0 0 0 1 0" />
               <feBlend mode="normal" in2="shape" result="effect1_innerShadow_120_61" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="-0.1" dy="0.1" />
               <feGaussianBlur stdDeviation="0.05" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.721569 0 0 0 0 0.690196 0 0 0 0 0.701961 0 0 0 1 0" />
               <feBlend mode="normal" in2="effect1_innerShadow_120_61" result="effect2_innerShadow_120_61" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="0.1" dy="-0.1" />
               <feGaussianBlur stdDeviation="0.05" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.615686 0 0 0 0 0.560784 0 0 0 0 0.65098 0 0 0 1 0" />
               <feBlend mode="normal" in2="effect2_innerShadow_120_61" result="effect3_innerShadow_120_61" />
            </filter>
            <filter
               id="filter2_i_120_61"
               x="4.17468"
               y="45.0267"
               width="15.1479"
               height="14.2339"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dy="-0.2" />
               <feGaussianBlur stdDeviation="0.2" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.847059 0 0 0 0 0.505882 0 0 0 0 0.360784 0 0 0 1 0" />
               <feBlend mode="normal" in2="shape" result="effect1_innerShadow_120_61" />
            </filter>
            <filter
               id="filter3_f_120_61"
               x="17.41"
               y="34.28"
               width="13.25"
               height="14.876"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_120_61" />
            </filter>
            <linearGradient
               id="paint0_linear_120_61"
               x1="16.222"
               y1="34.616"
               x2="6.222"
               y2="32.968"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#EE2452" />
               <stop offset="1" stopColor="#F63E7A" />
            </linearGradient>
            <radialGradient
               id="paint1_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(4.796 34.126) rotate(-19.9576) scale(16.0034 2.72091)"
            >
               <stop offset="0.164" stopColor="#FF8DB0" />
               <stop offset="1" stopColor="#FF8DB0" stopOpacity="0" />
            </radialGradient>
            <linearGradient
               id="paint2_linear_120_61"
               x1="9.81001"
               y1="39.466"
               x2="11.44"
               y2="37.876"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.491" stopColor="#DD467C" />
               <stop offset="1" stopColor="#DD467C" stopOpacity="0" />
            </linearGradient>
            <linearGradient
               id="paint3_linear_120_61"
               x1="35.7841"
               y1="43.344"
               x2="23.3481"
               y2="53.102"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F83267" />
               <stop offset="1" stopColor="#FF3190" />
            </linearGradient>
            <radialGradient
               id="paint4_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(29.1591 44.4053) rotate(86.482) scale(15.2781 13.1721)"
            >
               <stop offset="0.757" stopColor="#E76CBE" stopOpacity="0" />
               <stop offset="0.951" stopColor="#E76CBE" />
            </radialGradient>
            <radialGradient
               id="paint5_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(26.9081 52.468) rotate(-57.346) scale(10.541 4.63014)"
            >
               <stop stopColor="#FF5898" />
               <stop offset="1" stopColor="#FF5898" stopOpacity="0" />
            </radialGradient>
            <linearGradient
               id="paint6_linear_120_61"
               x1="26.9719"
               y1="17.344"
               x2="44.5959"
               y2="35.094"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#CCBBC0" />
               <stop offset="1" stopColor="#EAD2EC" />
            </linearGradient>
            <radialGradient
               id="paint7_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(47.9399 43.154) rotate(-142.447) scale(33.3538 62.6966)"
            >
               <stop offset="0.811" stopColor="#E7E5E5" stopOpacity="0" />
               <stop offset="1" stopColor="#E7E5E5" />
            </radialGradient>
            <radialGradient
               id="paint8_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(18.3619 42.62) rotate(-56.929) scale(19.6076 9.2154)"
            >
               <stop offset="0.281" stopColor="#B5A3A5" />
               <stop offset="1" stopColor="#B5A3A5" stopOpacity="0" />
            </radialGradient>
            <radialGradient
               id="paint9_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(12.6886 40.1815) rotate(-73.625) scale(17.3824 2.9454)"
            >
               <stop offset="0.208" stopColor="#B28F96" />
               <stop offset="1" stopColor="#B28F96" stopOpacity="0" />
            </radialGradient>
            <radialGradient
               id="paint10_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(54.2199 14.22) rotate(137.284) scale(26.35 8.18137)"
            >
               <stop stopColor="#FAECF1" />
               <stop offset="1" stopColor="#FAECF1" stopOpacity="0" />
            </radialGradient>
            <linearGradient
               id="paint11_linear_120_61"
               x1="35.562"
               y1="20.982"
               x2="49"
               y2="22.124"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#A796A0" />
               <stop offset="1" stopColor="#A5959F" />
            </linearGradient>
            <radialGradient
               id="paint12_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(42.284 22.124) rotate(90) scale(6.71876)"
            >
               <stop offset="0.587" stopColor="#93859B" />
               <stop offset="1" stopColor="#93859B" stopOpacity="0" />
            </radialGradient>
            <radialGradient
               id="paint13_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(42.28 26.776) rotate(-90) scale(9.69118 8.91252)"
            >
               <stop stopColor="#72CDFF" />
               <stop offset="0.738" stopColor="#66ACFF" />
               <stop offset="1" stopColor="#3B57F4" />
            </radialGradient>
            <radialGradient
               id="paint14_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(16.382 48.494) rotate(135) scale(11.6129 9.12801)"
            >
               <stop stopColor="#D46213" />
               <stop offset="1" stopColor="#FF9542" />
            </radialGradient>
            <radialGradient
               id="paint15_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(16.608 57.94) rotate(-131.186) scale(14.3491 15.1215)"
            >
               <stop offset="0.871" stopColor="#FFC484" stopOpacity="0" />
               <stop offset="1" stopColor="#FFC484" />
            </radialGradient>
            <linearGradient
               id="paint16_linear_120_61"
               x1="11.596"
               y1="43.906"
               x2="22.596"
               y2="54.718"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#452860" />
               <stop offset="1" stopColor="#51509F" />
            </linearGradient>
            <radialGradient
               id="paint17_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(13.66 42.906) rotate(77.8285) scale(3.2608 2.881)"
            >
               <stop stopColor="#8E839A" />
               <stop offset="1" stopColor="#8E839A" stopOpacity="0" />
            </radialGradient>
            <radialGradient
               id="paint18_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(14.414 44.836) rotate(47.9357) scale(12.4246 6.51273)"
            >
               <stop offset="0.86" stopColor="#6175B9" stopOpacity="0" />
               <stop offset="1" stopColor="#6175B9" />
            </radialGradient>
            <linearGradient
               id="paint19_linear_120_61"
               x1="58.354"
               y1="5.752"
               x2="51.002"
               y2="13.104"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FF3745" />
               <stop offset="1" stopColor="#ED224B" />
            </linearGradient>
            <radialGradient
               id="paint20_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(57.4779 8.69586) rotate(135) scale(16.4935 3.9594)"
            >
               <stop offset="0.189" stopColor="#FF5971" />
               <stop offset="1" stopColor="#FF5971" stopOpacity="0" />
            </radialGradient>
            <linearGradient
               id="paint21_linear_120_61"
               x1="51.644"
               y1="13.594"
               x2="52.378"
               y2="12.828"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#D2575E" />
               <stop offset="1" stopColor="#F93251" stopOpacity="0" />
            </linearGradient>
            <linearGradient
               id="paint22_linear_120_61"
               x1="30.3201"
               y1="34.13"
               x2="17.3481"
               y2="47.102"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FF2644" />
               <stop offset="1" stopColor="#FF2982" />
            </linearGradient>
            <radialGradient
               id="paint23_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(12.4721 38.844) rotate(-20.865) scale(14.9156 3.90479)"
            >
               <stop stopColor="#FF95AF" />
               <stop offset="1" stopColor="#FF95AF" stopOpacity="0" />
            </radialGradient>
            <radialGradient
               id="paint24_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(10.7961 40.616) rotate(44.7044) scale(9.22043 2.72363)"
            >
               <stop offset="0.382" stopColor="#E14678" />
               <stop offset="1" stopColor="#E14678" stopOpacity="0" />
            </radialGradient>
            <radialGradient
               id="paint25_radial_120_61"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(23.1601 38.28) rotate(84.6743) scale(14.8139 15.7864)"
            >
               <stop offset="0.832" stopColor="#E67BC7" stopOpacity="0" />
               <stop offset="1" stopColor="#E67BC7" />
            </radialGradient>
            <linearGradient
               id="paint26_linear_120_61"
               x1="27.972"
               y1="36.218"
               x2="19.722"
               y2="44.844"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FF5970" />
               <stop offset="1" stopColor="#FF5794" />
            </linearGradient>
         </defs>
      </svg>
   );
}
