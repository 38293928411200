import Banner from "../../components/banner";
import Map from "../../components/map";
import Stepped from "../../components/stepped";
import TextBlock from "../../components/text-block";

import styles from "./index.module.scss";

const { mb_64, mb_0, pt_24 } = styles;

export default function AboutTransfer() {
   const steps = [
      {
         point: "1",
         content: "Оставляете заявку на денежный перевод",
      },
      {
         point: "2",
         content: "Наш сотрудник встретиться с Вами",
      },
      {
         point: "3",
         content: "Переводите деньги на наш счёт в РФ",
      },
      {
         point: "4",
         content: "Наш сотрудник выдаёт вам наличные в евро",
      },
   ];
   return (
      <>
         <Banner
            img="about-transfer-banner"
            alt={"Девушка в белом с умным видом смотрит в экран своего ноутбука."}
            rounded={true}
         />
         <TextBlock
            className={pt_24}
            content={[
               "В 2022 году из-за вторжения России на территорию Украины многие иностранные компании ввели санкции против России. Вероятнее всего самой ощутимой санкцией для многих россиян стало прекращение работы платёжных системы Visa и MasterCard на территории Российской федерации. И хотя внутри России банковские карты всё ещё продолжают работать, за её пределами оплатить ими что-либо уже невозможно.",
               "Но для людей, которые по соображениям собственной безопасности и безопасности своих близких уехали или собираются уехать в Черногорию, всё ещё есть решение!",
            ]}
         />
         <TextBlock
            title={"Как перевести деньги из России в Черногорию?"}
            content={[
               "Россия не за железным занавесом и сейчас есть возможности перевести свои честно заработанные рубли в Черногорию. На нашем сайте Вы можете в пару кликов оформить заявку на перевод денежных средств в наличные евро в Черногории со счёта в Российском банке.",
               "После заполнения формы мы назначим курьера, который доставит Вам деньги, дополнительно уведомим Вас, о том что заявка принята в работу и отправим ссылку на денежный перевод.",
            ]}
         >
            <Stepped steps={steps} className={mb_0} />
         </TextBlock>
         <TextBlock
            title={"Мне нужно переводить деньги по ссылке до получения наличных?"}
            content={[
               "Нет, вы делаете перевод только тогда, когда встретитесь с нашим курьером. Как только курьер получит уведомление о зачислении средств, он выдаст Вам деньги наличными в евро.",
            ]}
            buttonText={"Оформить перевод"}
            to={"/transfer"}
            className={mb_64}
         />
         <Map />
      </>
   );
}
