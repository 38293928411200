import Picture from "../picture";

import classes from "./styles.module.scss";

const { banner, banner_rounded } = classes;

function Banner({ img, alt, rounded }) {
   return (
      <Picture
         fileName={img ? img : ""}
         alt={alt ? alt : ""}
         className={rounded ? banner + " " + banner_rounded : banner}
      />
   );
}

export default Banner;
