import styles from "./index.module.scss";

const { button } = styles;

function Button({ type, className, children, action }) {
   return (
      <button type={type ? type : "button"} className={className ? button + " " + className : button} onClick={action}>
         <p>{children ? children : "Кнопка"}</p>
      </button>
   );
}

export default Button;
