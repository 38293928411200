import { useNavigate } from "react-router-dom";
import Button from "../button";

import styles from "./index.module.scss";

const { text_block, text_block__title, text_block__content, text_block__action, mb_28, mb_24 } = styles;

export default function TextBlock({ title, content, children, buttonText, className, to }) {
   const navigate = useNavigate();
   return (
      <section className={text_block + " " + className}>
         {title ? <h2 className={text_block__title + " " + mb_24}>{title}</h2> : null}
         {content.map((item, key) => (
            <p className={text_block__content + " " + mb_28} key={key}>
               {item}
            </p>
         ))}
         {children ? children : null}
         {buttonText ? (
            <div className={text_block__action}>
               <Button type={"button"} action={() => navigate(to)}>
                  {buttonText ? buttonText : null}
               </Button>
            </div>
         ) : null}
      </section>
   );
}
