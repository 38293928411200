import Button from "../button";
import Picture from "../picture";

import styles from "./index.module.scss";

const {
   text_block,
   text_block__content,
   text_block__title,
   text_block__wrapper_text,
   text_block__text,
   text_block__content_picture,
   picture,
   picture_blur,
   picture_left,
} = styles;

export default function PictureTextBlock({ title, text, pictureName, pictureAlt, ltr, isButton, buttonAction }) {
   return (
      <section className={text_block}>
         {ltr ? (
            <div className={text_block__content_picture}>
               <Picture className={picture + " " + picture_left} fileName={pictureName} alt={pictureAlt} lazy={true} />
               <Picture
                  className={picture + " " + picture_blur + " " + picture_left}
                  fileName={pictureName}
                  alt={pictureAlt}
                  lazy={true}
               />
            </div>
         ) : (
            ""
         )}
         <div className={text_block__content}>
            <h2 className={text_block__title}>{title}</h2>
            <div className={text_block__wrapper_text}>
               {text.map((item, key) => (
                  <p className={text_block__text} key={key}>
                     {item}
                  </p>
               ))}
            </div>
            {isButton ? (
               <>
                  <Button type={"button"} action={buttonAction}>
                     Оформить превод
                  </Button>
               </>
            ) : (
               ""
            )}
         </div>
         {ltr ? (
            ""
         ) : (
            <div className={text_block__content_picture}>
               <Picture className={picture} fileName={pictureName} alt={pictureAlt} lazy={true} />
               <Picture className={picture + " " + picture_blur} fileName={pictureName} alt={pictureAlt} lazy={true} />
            </div>
         )}
      </section>
   );
}
