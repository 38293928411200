export default function Facebook(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "20"}
         height={props.height ? props.height : "21"}
         viewBox="0 0 20 21"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M20 10.2883C20 4.73149 15.5231 0.227051 10.0012 0.227051C4.47694 0.228301 0 4.73149 0 10.2895C0 15.3102 3.65704 19.4721 8.43645 20.227V13.1967H5.89926V10.2895H8.43894V8.07107C8.43894 5.55014 9.93251 4.15781 12.216 4.15781C13.3108 4.15781 14.4544 4.35403 14.4544 4.35403V6.82873H13.1933C11.9523 6.82873 11.5648 7.60488 11.5648 8.40103V10.2883H14.337L13.8945 13.1954H11.5636V20.2258C16.343 19.4709 20 15.3089 20 10.2883Z"
            fill="#0070F3"
         />
      </svg>
   );
}
