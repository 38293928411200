import styles from "./index.module.scss";

const { image } = styles;

function Picture({ className, fileName, alt, lazy }) {
   return (
      <picture>
         <source
            media="(min-width: 920px)"
            srcSet={fileName ? `/assets/webp/lg/${fileName}@1x.webp 1x, /assets/webp/lg/${fileName}@2x.webp 2x` : ""}
            type="image/webp"
         />
         <source
            media="(min-width: 500px)"
            srcSet={fileName ? `/assets/webp/s/${fileName}-s@1x.webp 1x, /assets/webp/s/${fileName}-s@2x.webp 2x` : ""}
            type="image/webp"
         />
         <source
            media="(min-width: 920px)"
            srcSet={fileName ? `/assets/jpeg/lg/${fileName}@1x.jpg 1x, /assets/jpeg/lg/${fileName}@2x.jpg 2x` : ""}
         />
         <source
            media="(min-width: 500px)"
            srcSet={fileName ? `/assets/jpeg/s/${fileName}-s@1x.jpg 1x, /assets/jpeg/s/${fileName}-s@2x.jpg 2x` : ""}
         />
         <source
            srcSet={
               fileName ? `/assets/webp/xs/${fileName}-xs@1x.webp 1x, /assets/webp/xs/${fileName}-xs@2x.webp 2x` : ""
            }
            type="image/webp"
         />
         <img
            className={className ? className : image}
            src={fileName ? `/assets/jpeg/xs/${fileName}-xs@1x.jpg` : ""}
            alt={alt ? alt : ""}
            srcSet={
               fileName ? `/assets/jpeg/xs/${fileName}-xs@1x.jpg 1x, /assets/jpeg/xs/${fileName}-xs@2x.jpg 2x` : ""
            }
            loading={lazy ? "lazy" : ""}
            decoding="async"
         />
      </picture>
   );
}

export default Picture;
