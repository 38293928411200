import { Outlet } from "react-router-dom";
import Header from "../components/header";
import HeaderBottom from "../components/header-bottom";

import styles from "./app.module.scss";

const { wrapper } = styles;

function App() {
   return (
      <>
         <Header />
         <main className={wrapper}>
            <Outlet />
         </main>
         <HeaderBottom />
      </>
   );
}

export default App;
