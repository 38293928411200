export default function Logo(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "139"}
         height={props.height ? props.height : "32"}
         viewBox="0 0 139 32"
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
      >
         <path
            d="M0 15.773C0 8.31369 0 4.58401 2.292 2.292C4.58401 0 8.31369 0 15.773 0C23.2324 0 26.9621 0 29.2541 2.292C31.5461 4.58401 31.5461 8.31369 31.5461 15.773C31.5461 23.2324 31.5461 26.9621 29.2541 29.2541C26.9621 31.5461 23.2324 31.5461 15.773 31.5461C8.31369 31.5461 4.58401 31.5461 2.292 29.2541C0 26.9621 0 23.2324 0 15.773Z"
            fill="url(#paint0_linear_357_139)" />
         <path
            d="M10.9071 0.0342211C10.371 0.478279 9.8759 0.950642 9.41941 1.44785C7.04554 4.03351 5.90847 7.08642 5.35724 9.7766H0.0712165C0.0121946 11.1708 0.00220842 12.77 0.000518799 14.615H5.00696L5.01068 17.1221H0.000726071C0.00306587 18.9707 0.0153349 20.5694 0.0796692 21.9604H5.37675C5.93893 24.6455 7.08898 27.6819 9.46943 30.2492C9.87784 30.6897 10.3168 31.1104 10.788 31.5089C12.2355 31.5461 13.8848 31.5461 15.7732 31.5461C20.1521 31.5461 23.2457 31.5461 25.5177 31.0824C25.283 31.0065 25.0326 30.9655 24.7726 30.9655C18.4679 30.9655 15.0066 29.105 13.0174 26.9596C11.6582 25.4936 10.8388 23.7374 10.3512 21.9604L17.4889 21.9604C18.825 21.9604 19.9081 20.8773 19.9081 19.5413C19.9081 18.2052 18.825 17.1221 17.4889 17.1221L9.84904 17.1221L9.84533 14.615L17.4889 14.615C18.825 14.615 19.9081 13.5319 19.9081 12.1958C19.9081 10.8597 18.825 9.7766 17.4889 9.7766L10.3257 9.7766C10.806 7.9829 11.6205 6.20466 12.9835 4.72001C14.9687 2.55773 18.4341 0.67737 24.7726 0.67737C25.0956 0.67737 25.4039 0.614039 25.6857 0.499115C23.3988 9.22844e-08 20.261 0 15.7732 0C13.9364 0 12.3258 2.88389e-08 10.9071 0.0342211Z"
            fill="white" fillOpacity="0.9" />
         <path
            d="M10.9071 0.0342211C10.371 0.478279 9.8759 0.950642 9.41941 1.44785C7.04554 4.03351 5.90847 7.08642 5.35724 9.7766H0.0712165C0.0121946 11.1708 0.00220842 12.77 0.000518799 14.615H5.00696L5.01068 17.1221H0.000726071C0.00306587 18.9707 0.0153349 20.5694 0.0796692 21.9604H5.37675C5.93893 24.6455 7.08898 27.6819 9.46943 30.2492C9.87784 30.6897 10.3168 31.1104 10.788 31.5089C12.2355 31.5461 13.8848 31.5461 15.7732 31.5461C20.1521 31.5461 23.2457 31.5461 25.5177 31.0824C25.283 31.0065 25.0326 30.9655 24.7726 30.9655C18.4679 30.9655 15.0066 29.105 13.0174 26.9596C11.6582 25.4936 10.8388 23.7374 10.3512 21.9604L17.4889 21.9604C18.825 21.9604 19.9081 20.8773 19.9081 19.5413C19.9081 18.2052 18.825 17.1221 17.4889 17.1221L9.84904 17.1221L9.84533 14.615L17.4889 14.615C18.825 14.615 19.9081 13.5319 19.9081 12.1958C19.9081 10.8597 18.825 9.7766 17.4889 9.7766L10.3257 9.7766C10.806 7.9829 11.6205 6.20466 12.9835 4.72001C14.9687 2.55773 18.4341 0.67737 24.7726 0.67737C25.0956 0.67737 25.4039 0.614039 25.6857 0.499115C23.3988 9.22844e-08 20.261 0 15.7732 0C13.9364 0 12.3258 2.88389e-08 10.9071 0.0342211Z"
            fill="url(#paint1_radial_357_139)" fillOpacity="0.6" />
         <path
            d="M41.6313 24.6452V8.0835H44.8017L51.0052 18.3672L57.1046 8.0835H60.2514L60.2987 24.6452H56.7024L56.6811 14.7048L51.8049 22.8944H50.0777L45.2275 14.9189V24.6452H41.6313Z"
            fill="#242424" />
         <path
            d="M70.0246 24.8345C68.6681 24.8345 67.4615 24.5506 66.4047 23.9827C65.3637 23.4149 64.5356 22.642 63.9204 21.6641C63.3211 20.6704 63.0214 19.5426 63.0214 18.2808C63.0214 17.0032 63.3211 15.8754 63.9204 14.8975C64.5356 13.9038 65.3637 13.1309 66.4047 12.5788C67.4615 12.011 68.6681 11.7271 70.0246 11.7271C71.3653 11.7271 72.5641 12.011 73.6209 12.5788C74.6777 13.1309 75.5057 13.8959 76.1051 14.8738C76.7045 15.8517 77.0042 16.9874 77.0042 18.2808C77.0042 19.5426 76.7045 20.6704 76.1051 21.6641C75.5057 22.642 74.6777 23.4149 73.6209 23.9827C72.5641 24.5506 71.3653 24.8345 70.0246 24.8345ZM70.0246 21.8061C70.6398 21.8061 71.1918 21.6641 71.6808 21.3802C72.1697 21.0963 72.5562 20.6941 72.8401 20.1735C73.124 19.6373 73.266 19.0063 73.266 18.2808C73.266 17.5394 73.124 16.9085 72.8401 16.388C72.5562 15.8675 72.1697 15.4653 71.6808 15.1814C71.1918 14.8975 70.6398 14.7555 70.0246 14.7555C69.4095 14.7555 68.8574 14.8975 68.3684 15.1814C67.8795 15.4653 67.4851 15.8675 67.1855 16.388C66.9015 16.9085 66.7596 17.5394 66.7596 18.2808C66.7596 19.0063 66.9015 19.6373 67.1855 20.1735C67.4851 20.6941 67.8795 21.0963 68.3684 21.3802C68.8574 21.6641 69.4095 21.8061 70.0246 21.8061Z"
            fill="#242424" />
         <path
            d="M85.5281 24.8345C84.1716 24.8345 82.965 24.5506 81.9082 23.9827C80.8672 23.4149 80.0391 22.642 79.4239 21.6641C78.8246 20.6704 78.5249 19.5426 78.5249 18.2808C78.5249 17.0032 78.8246 15.8754 79.4239 14.8975C80.0391 13.9038 80.8672 13.1309 81.9082 12.5788C82.965 12.011 84.1716 11.7271 85.5281 11.7271C86.8688 11.7271 88.0676 12.011 89.1244 12.5788C90.1811 13.1309 91.0092 13.8959 91.6086 14.8738C92.208 15.8517 92.5077 16.9874 92.5077 18.2808C92.5077 19.5426 92.208 20.6704 91.6086 21.6641C91.0092 22.642 90.1811 23.4149 89.1244 23.9827C88.0676 24.5506 86.8688 24.8345 85.5281 24.8345ZM85.5281 21.8061C86.1432 21.8061 86.6953 21.6641 87.1843 21.3802C87.6732 21.0963 88.0597 20.6941 88.3436 20.1735C88.6275 19.6373 88.7695 19.0063 88.7695 18.2808C88.7695 17.5394 88.6275 16.9085 88.3436 16.388C88.0597 15.8675 87.6732 15.4653 87.1843 15.1814C86.6953 14.8975 86.1432 14.7555 85.5281 14.7555C84.913 14.7555 84.3609 14.8975 83.8719 15.1814C83.383 15.4653 82.9886 15.8675 82.689 16.388C82.405 16.9085 82.2631 17.5394 82.2631 18.2808C82.2631 19.0063 82.405 19.6373 82.689 20.1735C82.9886 20.6941 83.383 21.0963 83.8719 21.3802C84.3609 21.6641 84.913 21.8061 85.5281 21.8061Z"
            fill="#242424" />
         <path
            d="M95.235 24.6452V8.0835H102.404C103.887 8.0835 105.164 8.32798 106.237 8.81694C107.309 9.29013 108.137 9.97626 108.721 10.8753C109.305 11.7744 109.596 12.847 109.596 14.093C109.596 15.3233 109.305 16.388 108.721 17.2871C108.137 18.1861 107.309 18.8801 106.237 19.3691C105.164 19.8423 103.887 20.0789 102.404 20.0789H99.0678V24.6452H95.235ZM99.0678 16.9558H102.191C103.374 16.9558 104.257 16.7035 104.841 16.1987C105.424 15.694 105.716 14.9921 105.716 14.093C105.716 13.1782 105.424 12.4684 104.841 11.9637C104.257 11.4589 103.374 11.2066 102.191 11.2066H99.0678V16.9558Z"
            fill="#242424" />
         <path fillRule="evenodd" clipRule="evenodd"
            d="M119.528 17.1688C119.528 16.3801 119.284 15.765 118.795 15.3233C118.321 14.8817 117.588 14.6609 116.594 14.6609C115.916 14.6609 115.246 14.7713 114.583 14.9921C113.936 15.1971 113.384 15.4811 112.927 15.8438L111.602 13.2649C112.296 12.776 113.132 12.3974 114.11 12.1293C115.088 11.8611 116.082 11.7271 117.091 11.7271C119.031 11.7271 120.537 12.1845 121.61 13.0993C122.683 14.0142 123.219 15.4416 123.219 17.3817V24.6452H119.765V23.0635C119.487 23.5307 119.108 23.9081 118.629 24.1957C117.919 24.6215 117.004 24.8345 115.884 24.8345C114.891 24.8345 114.039 24.6689 113.329 24.3376C112.619 23.9906 112.075 23.5253 111.697 22.9417C111.318 22.3581 111.129 21.7035 111.129 20.978C111.129 20.2209 111.31 19.5584 111.673 18.9906C112.052 18.4227 112.643 17.9811 113.448 17.6656C114.252 17.3344 115.301 17.1688 116.594 17.1688H119.528ZM115.198 19.7477C115.53 19.4638 116.129 19.3218 116.996 19.3218H119.528V20.6231C119.307 21.1909 118.952 21.6247 118.463 21.9243C117.974 22.2083 117.422 22.3502 116.807 22.3502C116.16 22.3502 115.648 22.2161 115.269 21.948C114.907 21.6641 114.725 21.2855 114.725 20.8123C114.725 20.3865 114.883 20.0316 115.198 19.7477Z"
            fill="#242424" />
         <path
            d="M127.937 29.4244C127.275 29.4244 126.62 29.3219 125.973 29.1169C125.327 28.9118 124.798 28.6279 124.388 28.2651L125.737 25.6389C126.021 25.8913 126.344 26.0884 126.707 26.2304C127.085 26.3723 127.456 26.4433 127.819 26.4433C128.339 26.4433 128.749 26.3171 129.049 26.0648C129.365 25.8282 129.649 25.426 129.901 24.8581L129.946 24.7515L124.436 11.9163H128.245L131.818 20.561L135.414 11.9163H138.963L133.213 25.426C132.803 26.4512 132.33 27.2556 131.794 27.8392C131.273 28.4228 130.69 28.8329 130.043 29.0695C129.412 29.3061 128.71 29.4244 127.937 29.4244Z"
            fill="#242424" />
         <defs>
            <linearGradient id="paint0_linear_357_139" x1="3.04817" y1="1.40313" x2="29.0302" y2="30.5784"
                  gradientUnits="userSpaceOnUse">
                  <stop stopColor="#355FF4" />
                  <stop offset="1" stopColor="#000AFF" />
            </linearGradient>
            <radialGradient id="paint1_radial_357_139" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(24.1525 0.985815) rotate(118.61) scale(37.0567 69.5174)">
                  <stop stopColor="#35C2FF" />
                  <stop offset="1" stopColor="#B40DEF" />
            </radialGradient>
         </defs>
      </svg>
      
   );
}
