import { Link, useNavigate } from "react-router-dom";
import Button from "../button";

import Logo from "../icons/logo";

import styles from "./index.module.scss";

const { header, header__logo, header__navigation, header__navigation_item, header__navigation_itemShow } = styles;

function Header() {
   const navigate = useNavigate();
   return (
      <header className={header}>
         <Link to="/" className={header__logo}>
            <Logo />
         </Link>
         <nav className={header__navigation}>
            <Link to="/about-transfers" className={header__navigation_item}>
               Переводы
            </Link>
            <Link to="/about-service" className={header__navigation_item + " " + header__navigation_itemShow}>
               О сервисе
            </Link>
            <div className={header__navigation_item}>
               <Button type="button" action={() => navigate("/transfer")}>
                  Оформить первод
               </Button>
            </div>
         </nav>
      </header>
   );
}

export default Header;
