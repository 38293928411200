import classes from "./styles.module.scss";

const {
    advantagesCard,
    advantagesCard_icon,
    advantagesCard_text,
    advantagesCard_title,
    advantagesCard_description
} = classes;

function AdvantagesCard ({Icon, title, description}) {

    return (
        <div className={advantagesCard}>
            <Icon className={advantagesCard_icon} />
            <div className={advantagesCard_text}>
                <h2
                    className={advantagesCard_title}
                    dangerouslySetInnerHTML={{__html: title}}
                />
                <p
                    className={advantagesCard_description}
                    dangerouslySetInnerHTML={{__html: description}}
                />
            </div>
        </div>
    );
}

export default AdvantagesCard;