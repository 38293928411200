import { useNavigate } from "react-router-dom";
import Button from "../button";
import ButtonIcon from "../button-icon";
import About from "../icons/about";
import styles from "./index.module.scss";

const { menu, menu__about, menu__about_icon, menu__action } = styles;

function HeaderBottom() {
   const navigate = useNavigate();
   return (
      <div className={menu}>
         <div className={menu__about}>
            <ButtonIcon type="button" action={() => navigate("/about-transfers")}>
               <About className={menu__about_icon} />
               <p>О переводах</p>
            </ButtonIcon>
         </div>
         <div className={menu__action}>
            <Button type="button" action={() => navigate("/transfer")}>
               Оформить первод
            </Button>
         </div>
      </div>
   );
}

export default HeaderBottom;
