import { Link } from "react-router-dom";
import styles from "./error-page.module.scss";

const { errorPage, errorPage__title, errorPage__text, errorPage__linkBack } = styles;

function ErrorPages() {
   return (
      <>
         <div className={errorPage}>
            <h1 className={errorPage__title}>404</h1>
            <p className={errorPage__text}>
               We're fairly sure that page used to be here, but seems to have gone missing. We do apologise on it's
               behalf.
            </p>
            <Link to="/" className={errorPage__linkBack}>
               Обратно на MooPay.com
            </Link>
         </div>
      </>
   );
}

export default ErrorPages;
