export default function About(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "28"}
         height={props.height ? props.height : "29"}
         viewBox="0 0 28 29"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M12.83 11H14a1.17 1.17 0 1 0 0-2.33h-1.17M14 23.83a9.35 9.35 0 0 1 0-18.66 9.35 9.35 0 0 1 0 18.66Zm0-21a11.67 11.67 0 1 0 0 23.34 11.67 11.67 0 0 0 0-23.34Zm-1.17 16.34a1.17 1.17 0 0 0 2.33 0V14.5c0-.64-.52-1.17-1.16-1.17-.64 0-1.17.53-1.17 1.17v4.67Z"
            fill="#0070F3"
         />
      </svg>
   );
}
