function LineVertical(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "47"}
         height={props.height ? props.height : "329"}
         viewBox={`0 0 47 329`}
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M23.29 324.34a88.76 88.76 0 0 1-1.75-108.02l5.2-7a80.35 80.35 0 0 0 .26-95.35l-1.08-1.47A78.47 78.47 0 0 1 40.86 4.34"
            stroke="#0070F3"
            strokeWidth="8"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   );
}

export default LineVertical;
