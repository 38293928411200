function Line(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "996"}
         height={props.height ? props.height : "74"}
         viewBox="0 0 996 74"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M991.5 36.9a462.75 462.75 0 0 1-330.42 3.7l-25.8-9.53a421.69 421.69 0 0 0-290.76-.56l-34.6 12.64A347.05 347.05 0 0 1 4.5 9.9"
            stroke="#0070F3"
            strokeWidth="8"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   );
}

export default Line;
