import { createBrowserRouter } from "react-router-dom";

import AboutService from "../pages/about-service";
import AboutTransfer from "../pages/about-transfer";
import Lahding from "../pages/lending";
import PrivacyPolicy from "../pages/privacy-policy";
import Transfer from "../pages/transfer";
import ErrorPages from "./error-page";

import App from "./app";

const router = createBrowserRouter([
   {
      path: "/",
      element: <App />,
      errorElement: <ErrorPages />,
      children: [
         {
            index: true,
            element: <Lahding />,
         },
         {
            path: "/about-transfers",
            element: <AboutTransfer />,
         },
         {
            path: "/about-service",
            element: <AboutService />,
         },
         {
            path: "/transfer",
            element: <Transfer />,
         },
         {
            path: "/privacy-policy",
            element: <PrivacyPolicy />,
         },
      ],
   },
]);

export default router;
