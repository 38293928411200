export default function OkHand(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "64"}
         height={props.height ? props.height : "64"}
         viewBox="0 0 64 64"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <g filter="url(#filter0_i_120_114)">
            <path
               d="M30.312 10.212C29.9829 9.01319 30.1435 7.73276 30.7584 6.65237C31.3733 5.57197 32.3923 4.7801 33.591 4.45097C34.7898 4.12184 36.0703 4.2824 37.1507 4.89733C38.231 5.51226 39.0229 6.53119 39.352 7.72997L41.302 14.824V12.804C41.302 11.5691 41.7926 10.3849 42.6658 9.51168C43.5389 8.63851 44.7232 8.14797 45.958 8.14797C47.1929 8.14797 48.3772 8.63851 49.2503 9.51168C50.1235 10.3849 50.614 11.5691 50.614 12.804L50.614 31.876C50.614 32.008 50.608 32.14 50.598 32.27V36.138C50.598 48.574 45.518 54.044 40.518 57.232C36.5201 59.78 28.804 60.996 22.7101 57.78C18.7621 55.82 15.5981 52.496 13.8621 48.756C13.3028 47.5309 13.2508 46.1344 13.7173 44.8711C14.1839 43.6079 15.1312 42.5804 16.3524 42.0129C17.5737 41.4455 18.9699 41.3841 20.2362 41.8421C21.5026 42.3002 22.5364 43.2405 23.1121 44.458C24.4361 47.308 27.5121 49.428 30.4501 49.428C32.5142 49.428 34.4939 48.608 35.9535 47.1484C37.4131 45.6888 38.2331 43.7091 38.2331 41.645C38.2331 39.5808 37.4131 37.6012 35.9535 36.1416C34.4939 34.682 32.5142 33.862 30.4501 33.862C27.0701 33.862 24.3861 35.736 23.2641 38.39C23.0035 39.0074 22.6239 39.5674 22.1468 40.038C21.6697 40.5086 21.1046 40.8806 20.4837 41.1327C19.8627 41.3848 19.1982 41.512 18.5281 41.5072C17.858 41.5023 17.1954 41.3654 16.5782 41.1043C15.961 40.8432 15.4014 40.463 14.9312 39.9855C14.461 39.508 14.0895 38.9425 13.838 38.3214C13.5865 37.7002 13.4599 37.0356 13.4654 36.3655C13.4709 35.6954 13.6084 35.0329 13.8701 34.416C15.9561 29.486 20.1141 25.916 25.1301 24.428L17.7821 12.488C17.1236 11.3429 16.937 9.98629 17.262 8.70595C17.5869 7.4256 18.3978 6.32218 19.5227 5.62968C20.6475 4.93717 21.9979 4.71006 23.2874 4.99649C24.5769 5.28292 25.7041 6.06037 26.4301 7.16397L31.93 16.102L30.312 10.212V10.212Z"
               fill="#FFC03A"
            />
            <path
               d="M30.312 10.212C29.9829 9.01319 30.1435 7.73276 30.7584 6.65237C31.3733 5.57197 32.3923 4.7801 33.591 4.45097C34.7898 4.12184 36.0703 4.2824 37.1507 4.89733C38.231 5.51226 39.0229 6.53119 39.352 7.72997L41.302 14.824V12.804C41.302 11.5691 41.7926 10.3849 42.6658 9.51168C43.5389 8.63851 44.7232 8.14797 45.958 8.14797C47.1929 8.14797 48.3772 8.63851 49.2503 9.51168C50.1235 10.3849 50.614 11.5691 50.614 12.804L50.614 31.876C50.614 32.008 50.608 32.14 50.598 32.27V36.138C50.598 48.574 45.518 54.044 40.518 57.232C36.5201 59.78 28.804 60.996 22.7101 57.78C18.7621 55.82 15.5981 52.496 13.8621 48.756C13.3028 47.5309 13.2508 46.1344 13.7173 44.8711C14.1839 43.6079 15.1312 42.5804 16.3524 42.0129C17.5737 41.4455 18.9699 41.3841 20.2362 41.8421C21.5026 42.3002 22.5364 43.2405 23.1121 44.458C24.4361 47.308 27.5121 49.428 30.4501 49.428C32.5142 49.428 34.4939 48.608 35.9535 47.1484C37.4131 45.6888 38.2331 43.7091 38.2331 41.645C38.2331 39.5808 37.4131 37.6012 35.9535 36.1416C34.4939 34.682 32.5142 33.862 30.4501 33.862C27.0701 33.862 24.3861 35.736 23.2641 38.39C23.0035 39.0074 22.6239 39.5674 22.1468 40.038C21.6697 40.5086 21.1046 40.8806 20.4837 41.1327C19.8627 41.3848 19.1982 41.512 18.5281 41.5072C17.858 41.5023 17.1954 41.3654 16.5782 41.1043C15.961 40.8432 15.4014 40.463 14.9312 39.9855C14.461 39.508 14.0895 38.9425 13.838 38.3214C13.5865 37.7002 13.4599 37.0356 13.4654 36.3655C13.4709 35.6954 13.6084 35.0329 13.8701 34.416C15.9561 29.486 20.1141 25.916 25.1301 24.428L17.7821 12.488C17.1236 11.3429 16.937 9.98629 17.262 8.70595C17.5869 7.4256 18.3978 6.32218 19.5227 5.62968C20.6475 4.93717 21.9979 4.71006 23.2874 4.99649C24.5769 5.28292 25.7041 6.06037 26.4301 7.16397L31.93 16.102L30.312 10.212V10.212Z"
               fill="url(#paint0_radial_120_114)"
            />
            <path
               d="M30.312 10.212C29.9829 9.01319 30.1435 7.73276 30.7584 6.65237C31.3733 5.57197 32.3923 4.7801 33.591 4.45097C34.7898 4.12184 36.0703 4.2824 37.1507 4.89733C38.231 5.51226 39.0229 6.53119 39.352 7.72997L41.302 14.824V12.804C41.302 11.5691 41.7926 10.3849 42.6658 9.51168C43.5389 8.63851 44.7232 8.14797 45.958 8.14797C47.1929 8.14797 48.3772 8.63851 49.2503 9.51168C50.1235 10.3849 50.614 11.5691 50.614 12.804L50.614 31.876C50.614 32.008 50.608 32.14 50.598 32.27V36.138C50.598 48.574 45.518 54.044 40.518 57.232C36.5201 59.78 28.804 60.996 22.7101 57.78C18.7621 55.82 15.5981 52.496 13.8621 48.756C13.3028 47.5309 13.2508 46.1344 13.7173 44.8711C14.1839 43.6079 15.1312 42.5804 16.3524 42.0129C17.5737 41.4455 18.9699 41.3841 20.2362 41.8421C21.5026 42.3002 22.5364 43.2405 23.1121 44.458C24.4361 47.308 27.5121 49.428 30.4501 49.428C32.5142 49.428 34.4939 48.608 35.9535 47.1484C37.4131 45.6888 38.2331 43.7091 38.2331 41.645C38.2331 39.5808 37.4131 37.6012 35.9535 36.1416C34.4939 34.682 32.5142 33.862 30.4501 33.862C27.0701 33.862 24.3861 35.736 23.2641 38.39C23.0035 39.0074 22.6239 39.5674 22.1468 40.038C21.6697 40.5086 21.1046 40.8806 20.4837 41.1327C19.8627 41.3848 19.1982 41.512 18.5281 41.5072C17.858 41.5023 17.1954 41.3654 16.5782 41.1043C15.961 40.8432 15.4014 40.463 14.9312 39.9855C14.461 39.508 14.0895 38.9425 13.838 38.3214C13.5865 37.7002 13.4599 37.0356 13.4654 36.3655C13.4709 35.6954 13.6084 35.0329 13.8701 34.416C15.9561 29.486 20.1141 25.916 25.1301 24.428L17.7821 12.488C17.1236 11.3429 16.937 9.98629 17.262 8.70595C17.5869 7.4256 18.3978 6.32218 19.5227 5.62968C20.6475 4.93717 21.9979 4.71006 23.2874 4.99649C24.5769 5.28292 25.7041 6.06037 26.4301 7.16397L31.93 16.102L30.312 10.212V10.212Z"
               fill="url(#paint1_radial_120_114)"
            />
            <path
               d="M30.312 10.212C29.9829 9.01319 30.1435 7.73276 30.7584 6.65237C31.3733 5.57197 32.3923 4.7801 33.591 4.45097C34.7898 4.12184 36.0703 4.2824 37.1507 4.89733C38.231 5.51226 39.0229 6.53119 39.352 7.72997L41.302 14.824V12.804C41.302 11.5691 41.7926 10.3849 42.6658 9.51168C43.5389 8.63851 44.7232 8.14797 45.958 8.14797C47.1929 8.14797 48.3772 8.63851 49.2503 9.51168C50.1235 10.3849 50.614 11.5691 50.614 12.804L50.614 31.876C50.614 32.008 50.608 32.14 50.598 32.27V36.138C50.598 48.574 45.518 54.044 40.518 57.232C36.5201 59.78 28.804 60.996 22.7101 57.78C18.7621 55.82 15.5981 52.496 13.8621 48.756C13.3028 47.5309 13.2508 46.1344 13.7173 44.8711C14.1839 43.6079 15.1312 42.5804 16.3524 42.0129C17.5737 41.4455 18.9699 41.3841 20.2362 41.8421C21.5026 42.3002 22.5364 43.2405 23.1121 44.458C24.4361 47.308 27.5121 49.428 30.4501 49.428C32.5142 49.428 34.4939 48.608 35.9535 47.1484C37.4131 45.6888 38.2331 43.7091 38.2331 41.645C38.2331 39.5808 37.4131 37.6012 35.9535 36.1416C34.4939 34.682 32.5142 33.862 30.4501 33.862C27.0701 33.862 24.3861 35.736 23.2641 38.39C23.0035 39.0074 22.6239 39.5674 22.1468 40.038C21.6697 40.5086 21.1046 40.8806 20.4837 41.1327C19.8627 41.3848 19.1982 41.512 18.5281 41.5072C17.858 41.5023 17.1954 41.3654 16.5782 41.1043C15.961 40.8432 15.4014 40.463 14.9312 39.9855C14.461 39.508 14.0895 38.9425 13.838 38.3214C13.5865 37.7002 13.4599 37.0356 13.4654 36.3655C13.4709 35.6954 13.6084 35.0329 13.8701 34.416C15.9561 29.486 20.1141 25.916 25.1301 24.428L17.7821 12.488C17.1236 11.3429 16.937 9.98629 17.262 8.70595C17.5869 7.4256 18.3978 6.32218 19.5227 5.62968C20.6475 4.93717 21.9979 4.71006 23.2874 4.99649C24.5769 5.28292 25.7041 6.06037 26.4301 7.16397L31.93 16.102L30.312 10.212V10.212Z"
               fill="url(#paint2_radial_120_114)"
            />
            <path
               d="M30.312 10.212C29.9829 9.01319 30.1435 7.73276 30.7584 6.65237C31.3733 5.57197 32.3923 4.7801 33.591 4.45097C34.7898 4.12184 36.0703 4.2824 37.1507 4.89733C38.231 5.51226 39.0229 6.53119 39.352 7.72997L41.302 14.824V12.804C41.302 11.5691 41.7926 10.3849 42.6658 9.51168C43.5389 8.63851 44.7232 8.14797 45.958 8.14797C47.1929 8.14797 48.3772 8.63851 49.2503 9.51168C50.1235 10.3849 50.614 11.5691 50.614 12.804L50.614 31.876C50.614 32.008 50.608 32.14 50.598 32.27V36.138C50.598 48.574 45.518 54.044 40.518 57.232C36.5201 59.78 28.804 60.996 22.7101 57.78C18.7621 55.82 15.5981 52.496 13.8621 48.756C13.3028 47.5309 13.2508 46.1344 13.7173 44.8711C14.1839 43.6079 15.1312 42.5804 16.3524 42.0129C17.5737 41.4455 18.9699 41.3841 20.2362 41.8421C21.5026 42.3002 22.5364 43.2405 23.1121 44.458C24.4361 47.308 27.5121 49.428 30.4501 49.428C32.5142 49.428 34.4939 48.608 35.9535 47.1484C37.4131 45.6888 38.2331 43.7091 38.2331 41.645C38.2331 39.5808 37.4131 37.6012 35.9535 36.1416C34.4939 34.682 32.5142 33.862 30.4501 33.862C27.0701 33.862 24.3861 35.736 23.2641 38.39C23.0035 39.0074 22.6239 39.5674 22.1468 40.038C21.6697 40.5086 21.1046 40.8806 20.4837 41.1327C19.8627 41.3848 19.1982 41.512 18.5281 41.5072C17.858 41.5023 17.1954 41.3654 16.5782 41.1043C15.961 40.8432 15.4014 40.463 14.9312 39.9855C14.461 39.508 14.0895 38.9425 13.838 38.3214C13.5865 37.7002 13.4599 37.0356 13.4654 36.3655C13.4709 35.6954 13.6084 35.0329 13.8701 34.416C15.9561 29.486 20.1141 25.916 25.1301 24.428L17.7821 12.488C17.1236 11.3429 16.937 9.98629 17.262 8.70595C17.5869 7.4256 18.3978 6.32218 19.5227 5.62968C20.6475 4.93717 21.9979 4.71006 23.2874 4.99649C24.5769 5.28292 25.7041 6.06037 26.4301 7.16397L31.93 16.102L30.312 10.212V10.212Z"
               fill="url(#paint3_radial_120_114)"
            />
         </g>
         <path
            d="M43.872 24.0799H50.614V12.6959C50.614 11.4611 50.1235 10.2768 49.2503 9.40363C48.3771 8.53046 47.1929 8.03992 45.958 8.03992C44.7232 8.03992 43.5389 8.53046 42.6657 9.40363C41.7925 10.2768 41.302 11.4611 41.302 12.6959V14.7159L43.872 24.0759V24.0799Z"
            fill="url(#paint4_linear_120_114)"
         />
         <path
            d="M43.872 24.0799H50.614V12.6959C50.614 11.4611 50.1235 10.2768 49.2503 9.40363C48.3771 8.53046 47.1929 8.03992 45.958 8.03992C44.7232 8.03992 43.5389 8.53046 42.6657 9.40363C41.7925 10.2768 41.302 11.4611 41.302 12.6959V14.7159L43.872 24.0759V24.0799Z"
            fill="url(#paint5_linear_120_114)"
         />
         <path
            d="M43.872 24.0799H50.614V12.6959C50.614 11.4611 50.1235 10.2768 49.2503 9.40363C48.3771 8.53046 47.1929 8.03992 45.958 8.03992C44.7232 8.03992 43.5389 8.53046 42.6657 9.40363C41.7925 10.2768 41.302 11.4611 41.302 12.6959V14.7159L43.872 24.0759V24.0799Z"
            fill="url(#paint6_radial_120_114)"
         />
         <path
            d="M43.872 24.0799H50.614V12.6959C50.614 11.4611 50.1235 10.2768 49.2503 9.40363C48.3771 8.53046 47.1929 8.03992 45.958 8.03992C44.7232 8.03992 43.5389 8.53046 42.6657 9.40363C41.7925 10.2768 41.302 11.4611 41.302 12.6959V14.7159L43.872 24.0759V24.0799Z"
            fill="url(#paint7_linear_120_114)"
         />
         <path
            d="M43.872 24.0799H50.614V12.6959C50.614 11.4611 50.1235 10.2768 49.2503 9.40363C48.3771 8.53046 47.1929 8.03992 45.958 8.03992C44.7232 8.03992 43.5389 8.53046 42.6657 9.40363C41.7925 10.2768 41.302 11.4611 41.302 12.6959V14.7159L43.872 24.0759V24.0799Z"
            fill="url(#paint8_linear_120_114)"
         />
         <path
            d="M30.3121 10.212C29.9829 9.01319 30.1435 7.73276 30.7584 6.65237C31.3733 5.57197 32.3923 4.7801 33.5911 4.45097C34.7898 4.12184 36.0703 4.2824 37.1507 4.89733C38.2311 5.51226 39.0229 6.53119 39.3521 7.72997L43.7761 24.078L36.4861 24.078C36.3661 23.54 36.2601 23.134 35.9541 22.638L31.9301 16.102L30.3121 10.212V10.212Z"
            fill="url(#paint9_linear_120_114)"
         />
         <path
            d="M30.3121 10.212C29.9829 9.01319 30.1435 7.73276 30.7584 6.65237C31.3733 5.57197 32.3923 4.7801 33.5911 4.45097C34.7898 4.12184 36.0703 4.2824 37.1507 4.89733C38.2311 5.51226 39.0229 6.53119 39.3521 7.72997L43.7761 24.078L36.4861 24.078C36.3661 23.54 36.2601 23.134 35.9541 22.638L31.9301 16.102L30.3121 10.212V10.212Z"
            fill="url(#paint10_linear_120_114)"
         />
         <path
            d="M30.3121 10.212C29.9829 9.01319 30.1435 7.73276 30.7584 6.65237C31.3733 5.57197 32.3923 4.7801 33.5911 4.45097C34.7898 4.12184 36.0703 4.2824 37.1507 4.89733C38.2311 5.51226 39.0229 6.53119 39.3521 7.72997L43.7761 24.078L36.4861 24.078C36.3661 23.54 36.2601 23.134 35.9541 22.638L31.9301 16.102L30.3121 10.212V10.212Z"
            fill="url(#paint11_radial_120_114)"
         />
         <path
            d="M30.3121 10.212C29.9829 9.01319 30.1435 7.73276 30.7584 6.65237C31.3733 5.57197 32.3923 4.7801 33.5911 4.45097C34.7898 4.12184 36.0703 4.2824 37.1507 4.89733C38.2311 5.51226 39.0229 6.53119 39.3521 7.72997L43.7761 24.078L36.4861 24.078C36.3661 23.54 36.2601 23.134 35.9541 22.638L31.9301 16.102L30.3121 10.212V10.212Z"
            fill="url(#paint12_linear_120_114)"
         />
         <path
            d="M19.4441 5.48002C18.2972 6.18646 17.4779 7.31946 17.1662 8.6299C16.8545 9.94033 17.0761 11.3209 17.7821 12.468L25.1301 24.408C26.8221 23.908 29.4061 24.086 31.2441 24.086H38.2761C36.0261 22.938 35.6181 21.99 35.2061 21.32L26.4301 7.14402C25.724 5.99728 24.5914 5.17797 23.2813 4.86629C21.9712 4.55461 20.5909 4.77609 19.4441 5.48202V5.48002Z"
            fill="url(#paint13_linear_120_114)"
         />
         <path
            d="M19.4441 5.48002C18.2972 6.18646 17.4779 7.31946 17.1662 8.6299C16.8545 9.94033 17.0761 11.3209 17.7821 12.468L25.1301 24.408C26.8221 23.908 29.4061 24.086 31.2441 24.086H38.2761C36.0261 22.938 35.6181 21.99 35.2061 21.32L26.4301 7.14402C25.724 5.99728 24.5914 5.17797 23.2813 4.86629C21.9712 4.55461 20.5909 4.77609 19.4441 5.48202V5.48002Z"
            fill="url(#paint14_linear_120_114)"
         />
         <path
            d="M19.4441 5.48002C18.2972 6.18646 17.4779 7.31946 17.1662 8.6299C16.8545 9.94033 17.0761 11.3209 17.7821 12.468L25.1301 24.408C26.8221 23.908 29.4061 24.086 31.2441 24.086H38.2761C36.0261 22.938 35.6181 21.99 35.2061 21.32L26.4301 7.14402C25.724 5.99728 24.5914 5.17797 23.2813 4.86629C21.9712 4.55461 20.5909 4.77609 19.4441 5.48202V5.48002Z"
            fill="url(#paint15_linear_120_114)"
         />
         <path
            d="M19.4441 5.48002C18.2972 6.18646 17.4779 7.31946 17.1662 8.6299C16.8545 9.94033 17.0761 11.3209 17.7821 12.468L25.1301 24.408C26.8221 23.908 29.4061 24.086 31.2441 24.086H38.2761C36.0261 22.938 35.6181 21.99 35.2061 21.32L26.4301 7.14402C25.724 5.99728 24.5914 5.17797 23.2813 4.86629C21.9712 4.55461 20.5909 4.77609 19.4441 5.48202V5.48002Z"
            fill="url(#paint16_radial_120_114)"
         />
         <g filter="url(#filter1_f_120_114)">
            <path
               d="M19.764 44.976C21.352 47.932 24.972 52.068 30.144 52.66"
               stroke="url(#paint17_linear_120_114)"
               strokeWidth="1.5"
               strokeLinecap="round"
            />
         </g>
         <g filter="url(#filter2_f_120_114)">
            <path
               d="M34.2161 24.9099C39.3101 25.9899 48.8241 31.7499 46.1301 46.1499"
               stroke="url(#paint18_radial_120_114)"
               strokeWidth="2"
               strokeLinecap="round"
            />
         </g>
         <g filter="url(#filter3_f_120_114)">
            <path
               d="M22.848 7.21802L35.14 26.978"
               stroke="url(#paint19_linear_120_114)"
               strokeWidth="1.5"
               strokeLinecap="round"
            />
         </g>
         <g filter="url(#filter4_f_120_114)">
            <path
               d="M36.274 7.88L42.1361 29.248"
               stroke="url(#paint20_linear_120_114)"
               strokeWidth="1.5"
               strokeLinecap="round"
            />
         </g>
         <g filter="url(#filter5_f_120_114)">
            <path d="M46.77 13.7419V34.8259" stroke="url(#paint21_linear_120_114)" strokeLinecap="round" />
         </g>
         <defs>
            <filter
               id="filter0_i_120_114"
               x="13.4015"
               y="3.68369"
               width="37.8125"
               height="56.0331"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="0.6" dy="-0.6" />
               <feGaussianBlur stdDeviation="1" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.909804 0 0 0 0 0.494118 0 0 0 0 0.301961 0 0 0 1 0" />
               <feBlend mode="normal" in2="shape" result="effect1_innerShadow_120_114" />
            </filter>
            <filter
               id="filter1_f_120_114"
               x="17.5139"
               y="42.7258"
               width="14.8802"
               height="12.1842"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feGaussianBlur stdDeviation="0.75" result="effect1_foregroundBlur_120_114" />
            </filter>
            <filter
               id="filter2_f_120_114"
               x="31.2158"
               y="21.9097"
               width="18.3853"
               height="27.2405"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_120_114" />
            </filter>
            <filter
               id="filter3_f_120_114"
               x="20.5979"
               y="4.9679"
               width="16.7922"
               height="24.2603"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feGaussianBlur stdDeviation="0.75" result="effect1_foregroundBlur_120_114" />
            </filter>
            <filter
               id="filter4_f_120_114"
               x="34.0238"
               y="5.62976"
               width="10.3624"
               height="25.8684"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feGaussianBlur stdDeviation="0.75" result="effect1_foregroundBlur_120_114" />
            </filter>
            <filter
               id="filter5_f_120_114"
               x="45.27"
               y="12.2419"
               width="3"
               height="24.084"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_120_114" />
            </filter>
            <radialGradient
               id="paint0_radial_120_114"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(18.5201 41.544) rotate(90) scale(1.0659 2.28722)"
            >
               <stop stopColor="#BE6E00" />
               <stop offset="0.792" stopColor="#BE6E00" stopOpacity="0" />
            </radialGradient>
            <radialGradient
               id="paint1_radial_120_114"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(33.012 44.716) rotate(52.5999) scale(6.88772 8.44294)"
            >
               <stop offset="0.416" stopColor="#D08C3B" />
               <stop offset="0.882" stopColor="#D08C3B" stopOpacity="0" />
            </radialGradient>
            <radialGradient
               id="paint2_radial_120_114"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(33.3428 39.2699) rotate(-38.12) scale(11.7781 11.2065)"
            >
               <stop stopColor="#E17C51" />
               <stop offset="1" stopColor="#E17C51" stopOpacity="0" />
            </radialGradient>
            <radialGradient
               id="paint3_radial_120_114"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(55.2652 25.6813) rotate(147.419) scale(23.8024 48.931)"
            >
               <stop stopColor="#FFE452" />
               <stop offset="1" stopColor="#FFE452" stopOpacity="0" />
            </radialGradient>
            <linearGradient
               id="paint4_linear_120_114"
               x1="40.77"
               y1="18.8199"
               x2="46.794"
               y2="18.8199"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#DB8E1E" />
               <stop offset="1" stopColor="#DB8E1E" stopOpacity="0" />
            </linearGradient>
            <linearGradient
               id="paint5_linear_120_114"
               x1="41.738"
               y1="19.5719"
               x2="43.656"
               y2="19.0699"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E37917" />
               <stop offset="1" stopColor="#E37917" stopOpacity="0" />
            </linearGradient>
            <radialGradient
               id="paint6_radial_120_114"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(43.638 24.5939) rotate(-103.646) scale(6.3843 4.69662)"
            >
               <stop stopColor="#FFDD4B" />
               <stop offset="1" stopColor="#FFDD4B" stopOpacity="0" />
            </radialGradient>
            <linearGradient
               id="paint7_linear_120_114"
               x1="51.626"
               y1="20.6539"
               x2="47.852"
               y2="20.6539"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFDE3F" />
               <stop offset="1" stopColor="#FFDE3F" stopOpacity="0" />
            </linearGradient>
            <linearGradient
               id="paint8_linear_120_114"
               x1="50.942"
               y1="21.8559"
               x2="49.466"
               y2="21.8559"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FAC54C" />
               <stop offset="1" stopColor="#FAC54C" stopOpacity="0" />
            </linearGradient>
            <linearGradient
               id="paint9_linear_120_114"
               x1="31.3381"
               y1="19.25"
               x2="37.0101"
               y2="17.438"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#D58430" />
               <stop offset="1" stopColor="#D58430" stopOpacity="0" />
            </linearGradient>
            <linearGradient
               id="paint10_linear_120_114"
               x1="32.7001"
               y1="18.568"
               x2="34.5021"
               y2="17.808"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E37917" />
               <stop offset="1" stopColor="#E37917" stopOpacity="0" />
            </linearGradient>
            <radialGradient
               id="paint11_radial_120_114"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(29.3651 5.40646) rotate(41.186) scale(6.09944 11.8109)"
            >
               <stop stopColor="#DEA23E" />
               <stop offset="1" stopColor="#DEA23E" stopOpacity="0" />
            </radialGradient>
            <linearGradient
               id="paint12_linear_120_114"
               x1="42.8381"
               y1="16.624"
               x2="38.9641"
               y2="18.124"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFE54D" />
               <stop offset="1" stopColor="#FFE54D" stopOpacity="0" />
            </linearGradient>
            <linearGradient
               id="paint13_linear_120_114"
               x1="21.9641"
               y1="20.898"
               x2="28.6161"
               y2="17.042"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#D58441" />
               <stop offset="1" stopColor="#EFA230" stopOpacity="0" />
            </linearGradient>
            <linearGradient
               id="paint14_linear_120_114"
               x1="32.6501"
               y1="15"
               x2="29.5881"
               y2="17.196"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFE946" />
               <stop offset="1" stopColor="#FFE946" stopOpacity="0" />
            </linearGradient>
            <linearGradient
               id="paint15_linear_120_114"
               x1="38.2761"
               y1="24.296"
               x2="34.4161"
               y2="23.094"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.612" stopColor="#FFD747" />
               <stop offset="1" stopColor="#FFD747" stopOpacity="0" />
            </linearGradient>
            <radialGradient
               id="paint16_radial_120_114"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(18.7146 5.65795) rotate(54.462) scale(7.09536 9.29138)"
            >
               <stop stopColor="#DEA23E" />
               <stop offset="1" stopColor="#DEA23E" stopOpacity="0" />
            </radialGradient>
            <linearGradient
               id="paint17_linear_120_114"
               x1="20.356"
               y1="44.976"
               x2="34.024"
               y2="54.286"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFD535" />
               <stop offset="1" stopColor="#FFD535" stopOpacity="0" />
            </linearGradient>
            <radialGradient
               id="paint18_radial_120_114"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(45.2962 31.7346) rotate(153.789) scale(8.59828 41.4246)"
            >
               <stop stopColor="#FFEB5C" />
               <stop offset="1" stopColor="#FFE94A" stopOpacity="0" />
            </radialGradient>
            <linearGradient
               id="paint19_linear_120_114"
               x1="28.142"
               y1="13.08"
               x2="40.236"
               y2="30.702"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFE746" />
               <stop offset="1" stopColor="#FFE746" stopOpacity="0" />
            </linearGradient>
            <linearGradient
               id="paint20_linear_120_114"
               x1="40.4341"
               y1="13.08"
               x2="52.528"
               y2="30.702"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFE746" />
               <stop offset="1" stopColor="#FFE746" stopOpacity="0" />
            </linearGradient>
            <linearGradient id="paint21_linear_120_114" gradientUnits="userSpaceOnUse">
               <stop stopColor="#FFE949" />
               <stop offset="1" stopColor="#FFE949" stopOpacity="0" />
            </linearGradient>
         </defs>
      </svg>
   );
}
