export default function EuroBanknote(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "64"}
         height={props.height ? props.height : "64 "}
         viewBox="0 0 64 64"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M55.444 23.25L8.55601 23.25C6.12595 23.25 4.15601 25.2199 4.15601 27.65L4.15601 55.412C4.15601 57.8421 6.12595 59.812 8.55601 59.812L55.444 59.812C57.8741 59.812 59.844 57.8421 59.844 55.412L59.844 27.65C59.844 25.2199 57.8741 23.25 55.444 23.25Z"
            fill="url(#paint0_linear_120_95)"
         />
         <path
            d="M55.444 23.25L8.55601 23.25C6.12595 23.25 4.15601 25.2199 4.15601 27.65L4.15601 55.412C4.15601 57.8421 6.12595 59.812 8.55601 59.812L55.444 59.812C57.8741 59.812 59.844 57.8421 59.844 55.412L59.844 27.65C59.844 25.2199 57.8741 23.25 55.444 23.25Z"
            fill="url(#paint1_linear_120_95)"
         />
         <path
            d="M55.444 23.25L8.55601 23.25C6.12595 23.25 4.15601 25.2199 4.15601 27.65L4.15601 55.412C4.15601 57.8421 6.12595 59.812 8.55601 59.812L55.444 59.812C57.8741 59.812 59.844 57.8421 59.844 55.412L59.844 27.65C59.844 25.2199 57.8741 23.25 55.444 23.25Z"
            fill="url(#paint2_linear_120_95)"
         />
         <path
            d="M55.444 16.062L8.55601 16.062C6.12595 16.062 4.15601 18.032 4.15601 20.462L4.15601 48.224C4.15601 50.6541 6.12595 52.624 8.55601 52.624L55.444 52.624C57.8741 52.624 59.844 50.6541 59.844 48.224L59.844 20.462C59.844 18.032 57.8741 16.062 55.444 16.062Z"
            fill="url(#paint3_radial_120_95)"
         />
         <g filter="url(#filter0_iii_120_95)">
            <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M54.126 20.312L9.87598 20.312C9.47815 20.312 9.09662 20.47 8.81532 20.7514C8.53401 21.0327 8.37598 21.4142 8.37598 21.812L8.37598 46.874C8.37598 47.704 9.04598 48.374 9.87598 48.374L54.126 48.374C54.5238 48.374 54.9053 48.216 55.1866 47.9347C55.4679 47.6534 55.626 47.2718 55.626 46.874L55.626 21.814C55.626 21.4162 55.4679 21.0347 55.1866 20.7534C54.9053 20.472 54.5238 20.314 54.126 20.314V20.312ZM9.87598 18.812C9.08033 18.812 8.31727 19.1281 7.75466 19.6907C7.19205 20.2533 6.87598 21.0164 6.87598 21.812L6.87598 46.874C6.87598 47.6697 7.19205 48.4327 7.75466 48.9953C8.31727 49.5579 9.08033 49.874 9.87598 49.874L54.126 49.874C54.9216 49.874 55.6847 49.5579 56.2473 48.9953C56.8099 48.4327 57.126 47.6697 57.126 46.874L57.126 21.814C57.126 21.0184 56.8099 20.2553 56.2473 19.6927C55.6847 19.1301 54.9216 18.814 54.126 18.814L9.87598 18.814V18.812Z"
               fill="url(#paint4_linear_120_95)"
            />
         </g>
         <path
            d="M41.688 42.876C46.7966 42.876 50.938 39.0561 50.938 34.344C50.938 29.6319 46.7966 25.812 41.688 25.812C36.5794 25.812 32.438 29.6319 32.438 34.344C32.438 39.0561 36.5794 42.876 41.688 42.876Z"
            fill="#2E70A8"
         />
         <path
            d="M41.688 42.876C46.7966 42.876 50.938 39.0561 50.938 34.344C50.938 29.6319 46.7966 25.812 41.688 25.812C36.5794 25.812 32.438 29.6319 32.438 34.344C32.438 39.0561 36.5794 42.876 41.688 42.876Z"
            fill="url(#paint5_linear_120_95)"
         />
         <path
            d="M41.688 42.876C46.7966 42.876 50.938 39.0561 50.938 34.344C50.938 29.6319 46.7966 25.812 41.688 25.812C36.5794 25.812 32.438 29.6319 32.438 34.344C32.438 39.0561 36.5794 42.876 41.688 42.876Z"
            fill="url(#paint6_radial_120_95)"
         />
         <g filter="url(#filter1_f_120_95)">
            <path d="M29.938 16.626H38.25L38.25 52.626H29.624L29.938 16.626V16.626Z" fill="#1C6E85" />
         </g>
         <g filter="url(#filter2_f_120_95)">
            <path d="M29.968 59.438H38.25V52.624H29.624L29.968 59.438V59.438Z" fill="#164A75" />
         </g>
         <path d="M30.126 16.062H38.876L38.876 59.812H30.126L30.126 16.062Z" fill="url(#paint7_linear_120_95)" />
         <path d="M30.126 16.062H38.876L38.876 59.812H30.126L30.126 16.062Z" fill="url(#paint8_linear_120_95)" />
         <g filter="url(#filter3_iii_120_95)">
            <path
               d="M12.724 33.576H16.584V35.082H12.724C12.4058 35.082 12.1006 35.2085 11.8755 35.4335C11.6505 35.6586 11.524 35.9638 11.524 36.282C11.524 36.6003 11.6505 36.9055 11.8755 37.1306C12.1006 37.3556 12.4058 37.482 12.724 37.482H16.656C16.9003 39.1989 17.756 40.77 19.0659 41.9065C20.3758 43.043 22.0519 43.6685 23.786 43.668H26.13C26.4483 43.668 26.7535 43.5416 26.9786 43.3166C27.2036 43.0915 27.33 42.7863 27.33 42.468C27.33 42.1498 27.2036 41.8446 26.9786 41.6195C26.7535 41.3945 26.4483 41.268 26.13 41.268H23.786C22.6887 41.2686 21.6244 40.8931 20.7703 40.2042C19.9161 39.5153 19.3239 38.5546 19.092 37.482H24.224C24.3816 37.482 24.5377 37.451 24.6833 37.3907C24.8289 37.3304 24.9611 37.242 25.0726 37.1306C25.184 37.0191 25.2724 36.8869 25.3327 36.7413C25.393 36.5957 25.424 36.4396 25.424 36.282C25.424 36.1245 25.393 35.9684 25.3327 35.8228C25.2724 35.6772 25.184 35.5449 25.0726 35.4335C24.9611 35.3221 24.8289 35.2337 24.6833 35.1734C24.5377 35.1131 24.3816 35.082 24.224 35.082H18.984V33.576H24.224C24.3816 33.576 24.5377 33.545 24.6833 33.4847C24.8289 33.4244 24.9611 33.336 25.0726 33.2246C25.184 33.1131 25.2724 32.9809 25.3327 32.8353C25.393 32.6897 25.424 32.5336 25.424 32.376C25.424 32.2185 25.393 32.0624 25.3327 31.9168C25.2724 31.7712 25.184 31.6389 25.0726 31.5275C24.9611 31.4161 24.8289 31.3277 24.6833 31.2674C24.5377 31.2071 24.3816 31.176 24.224 31.176H19.074C19.2871 30.0828 19.8737 29.0976 20.7333 28.3894C21.593 27.6811 22.6722 27.2939 23.786 27.294L26.13 27.294C26.4483 27.294 26.7535 27.1676 26.9786 26.9426C27.2036 26.7175 27.33 26.4123 27.33 26.094C27.33 25.7758 27.2036 25.4706 26.9786 25.2455C26.7535 25.0205 26.4483 24.894 26.13 24.894H23.786C22.0357 24.8938 20.3451 25.5312 19.0306 26.687C17.7161 27.8429 16.8677 29.438 16.644 31.174H12.724C12.4058 31.174 12.1006 31.3005 11.8755 31.5255C11.6505 31.7506 11.524 32.0558 11.524 32.374C11.524 32.6923 11.6505 32.9975 11.8755 33.2226C12.1006 33.4476 12.4058 33.574 12.724 33.574V33.576Z"
               fill="url(#paint9_linear_120_95)"
            />
         </g>
         <defs>
            <filter
               id="filter0_iii_120_95"
               x="6.67598"
               y="18.812"
               width="50.65"
               height="31.262"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dy="0.2" />
               <feGaussianBlur stdDeviation="0.125" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.376471 0 0 0 0 0.603922 0 0 0 1 0" />
               <feBlend mode="normal" in2="shape" result="effect1_innerShadow_120_95" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="-0.2" />
               <feGaussianBlur stdDeviation="0.125" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.121569 0 0 0 0 0.341176 0 0 0 0 0.560784 0 0 0 1 0" />
               <feBlend mode="normal" in2="effect1_innerShadow_120_95" result="effect2_innerShadow_120_95" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="0.2" />
               <feGaussianBlur stdDeviation="0.125" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.533333 0 0 0 0 0.780392 0 0 0 1 0" />
               <feBlend mode="normal" in2="effect2_innerShadow_120_95" result="effect3_innerShadow_120_95" />
            </filter>
            <filter
               id="filter1_f_120_95"
               x="29.224"
               y="16.226"
               width="9.42598"
               height="36.8"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feGaussianBlur stdDeviation="0.2" result="effect1_foregroundBlur_120_95" />
            </filter>
            <filter
               id="filter2_f_120_95"
               x="29.374"
               y="52.374"
               width="9.12598"
               height="7.31396"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feGaussianBlur stdDeviation="0.125" result="effect1_foregroundBlur_120_95" />
            </filter>
            <filter
               id="filter3_iii_120_95"
               x="11.424"
               y="24.794"
               width="16.006"
               height="19.024"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="-0.1" dy="0.15" />
               <feGaussianBlur stdDeviation="0.125" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.509804 0 0 0 0 0.639216 0 0 0 1 0" />
               <feBlend mode="normal" in2="shape" result="effect1_innerShadow_120_95" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="0.1" dy="-0.1" />
               <feGaussianBlur stdDeviation="0.05" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.784314 0 0 0 0 0.760784 0 0 0 0 0.752941 0 0 0 1 0" />
               <feBlend mode="normal" in2="effect1_innerShadow_120_95" result="effect2_innerShadow_120_95" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="-0.1" dy="0.1" />
               <feGaussianBlur stdDeviation="0.05" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.996078 0 0 0 0 0.992157 0 0 0 1 0" />
               <feBlend mode="normal" in2="effect2_innerShadow_120_95" result="effect3_innerShadow_120_95" />
            </filter>
            <linearGradient
               id="paint0_linear_120_95"
               x1="56.218"
               y1="57.376"
               x2="6.09401"
               y2="57.376"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#18739D" />
               <stop offset="1" stopColor="#1B5186" />
            </linearGradient>
            <linearGradient
               id="paint1_linear_120_95"
               x1="59.844"
               y1="55.626"
               x2="54.532"
               y2="55.626"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#2091C5" />
               <stop offset="1" stopColor="#2091C5" stopOpacity="0" />
            </linearGradient>
            <linearGradient
               id="paint2_linear_120_95"
               x1="4.15601"
               y1="55.376"
               x2="9.65601"
               y2="55.376"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.557" stopColor="#1E4472" />
               <stop offset="1" stopColor="#1E4472" stopOpacity="0" />
            </linearGradient>
            <radialGradient
               id="paint3_radial_120_95"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(5.37601 17.5) rotate(48.2593) scale(38.8654 50.9664)"
            >
               <stop stopColor="#49D9F8" />
               <stop offset="1" stopColor="#37C8F5" />
            </radialGradient>
            <linearGradient
               id="paint4_linear_120_95"
               x1="51.25"
               y1="19.5"
               x2="15.75"
               y2="48.876"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#2D6FAB" />
               <stop offset="1" stopColor="#2D70AC" />
            </linearGradient>
            <linearGradient
               id="paint5_linear_120_95"
               x1="38.626"
               y1="35.376"
               x2="42.188"
               y2="35.376"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.228" stopColor="#2B6A9C" />
               <stop offset="1" stopColor="#2B6A9C" stopOpacity="0" />
            </linearGradient>
            <radialGradient
               id="paint6_radial_120_95"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(41.6868 35.6866) rotate(-40.802) scale(9.66024 9.49512)"
            >
               <stop offset="0.587" stopColor="#28669C" stopOpacity="0" />
               <stop offset="0.987" stopColor="#1D5083" />
            </radialGradient>
            <linearGradient
               id="paint7_linear_120_95"
               x1="34.5"
               y1="19.376"
               x2="34.5"
               y2="54"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFD425" />
               <stop offset="1" stopColor="#FFAD24" />
            </linearGradient>
            <linearGradient
               id="paint8_linear_120_95"
               x1="36.626"
               y1="59.812"
               x2="36.626"
               y2="52.126"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.894" stopColor="#CC8139" />
               <stop offset="1" stopColor="#CC8139" stopOpacity="0" />
            </linearGradient>
            <linearGradient
               id="paint9_linear_120_95"
               x1="19.426"
               y1="24.894"
               x2="19.426"
               y2="43.668"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#EFEAEA" />
               <stop offset="1" stopColor="#EAE3E3" />
            </linearGradient>
         </defs>
      </svg>
   );
}
