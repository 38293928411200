export default function Twitter(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "20"}
         height={props.height ? props.height : "17"}
         viewBox="0 0 20 17"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M20 2.01288C19.2639 2.34748 18.4704 2.56737 17.6482 2.67253C18.4895 2.16584 19.1396 1.36278 19.4455 0.397197C18.652 0.875209 17.7725 1.20982 16.8451 1.40102C16.0899 0.578842 15.0287 0.10083 13.8241 0.10083C11.5774 0.10083 9.74187 1.93639 9.74187 4.20217C9.74187 4.52722 9.78012 4.8427 9.84704 5.13907C6.4436 4.96699 3.413 3.33219 1.39579 0.856088C1.04207 1.45838 0.8413 2.16584 0.8413 2.91154C0.8413 4.33601 1.55832 5.59796 2.6673 6.31498C1.98853 6.31498 1.35755 6.12378 0.803059 5.83697V5.86565C0.803059 7.85418 2.21797 9.51766 4.09178 9.89051C3.49018 10.0551 2.8586 10.078 2.24665 9.95743C2.50632 10.7724 3.01485 11.4855 3.70078 11.9966C4.38671 12.5076 5.21553 12.7908 6.07075 12.8064C4.62106 13.954 2.82409 14.5744 0.975143 14.5655C0.650096 14.5655 0.325048 14.5463 0 14.5081C1.81644 15.6744 3.97706 16.3532 6.29063 16.3532C13.8241 16.3532 17.9637 10.1008 17.9637 4.68018C17.9637 4.49854 17.9637 4.32645 17.9541 4.14481C18.7572 3.57119 19.4455 2.84462 20 2.01288Z"
            fill="#0070F3"
         />
      </svg>
   );
}
