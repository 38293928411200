export default function Instagram(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "20"}
         height={props.height ? props.height : "21"}
         viewBox="0 0 20 21"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M5.8 0.227051H14.2C17.4 0.227051 20 2.82705 20 6.02705V14.4271C20 15.9653 19.3889 17.4406 18.3012 18.5283C17.2135 19.616 15.7383 20.2271 14.2 20.2271H5.8C2.6 20.2271 0 17.6271 0 14.4271V6.02705C0 4.4888 0.61107 3.01354 1.69878 1.92583C2.78649 0.838121 4.26174 0.227051 5.8 0.227051ZM5.6 2.22705C4.64522 2.22705 3.72955 2.60634 3.05442 3.28147C2.37928 3.9566 2 4.87227 2 5.82705V14.6271C2 16.6171 3.61 18.2271 5.6 18.2271H14.4C15.3548 18.2271 16.2705 17.8478 16.9456 17.1726C17.6207 16.4975 18 15.5818 18 14.6271V5.82705C18 3.83705 16.39 2.22705 14.4 2.22705H5.6ZM15.25 3.72705C15.5815 3.72705 15.8995 3.85875 16.1339 4.09317C16.3683 4.32759 16.5 4.64553 16.5 4.97705C16.5 5.30857 16.3683 5.62651 16.1339 5.86093C15.8995 6.09535 15.5815 6.22705 15.25 6.22705C14.9185 6.22705 14.6005 6.09535 14.3661 5.86093C14.1317 5.62651 14 5.30857 14 4.97705C14 4.64553 14.1317 4.32759 14.3661 4.09317C14.6005 3.85875 14.9185 3.72705 15.25 3.72705ZM10 5.22705C11.3261 5.22705 12.5979 5.75383 13.5355 6.69152C14.4732 7.6292 15 8.90097 15 10.2271C15 11.5531 14.4732 12.8249 13.5355 13.7626C12.5979 14.7003 11.3261 15.2271 10 15.2271C8.67392 15.2271 7.40215 14.7003 6.46447 13.7626C5.52678 12.8249 5 11.5531 5 10.2271C5 8.90097 5.52678 7.6292 6.46447 6.69152C7.40215 5.75383 8.67392 5.22705 10 5.22705ZM10 7.22705C9.20435 7.22705 8.44129 7.54312 7.87868 8.10573C7.31607 8.66834 7 9.4314 7 10.2271C7 11.0227 7.31607 11.7858 7.87868 12.3484C8.44129 12.911 9.20435 13.2271 10 13.2271C10.7956 13.2271 11.5587 12.911 12.1213 12.3484C12.6839 11.7858 13 11.0227 13 10.2271C13 9.4314 12.6839 8.66834 12.1213 8.10573C11.5587 7.54312 10.7956 7.22705 10 7.22705Z"
            fill="#0070F3"
         />
      </svg>
   );
}
