import Line from "../icons/line";
import LineVertical from "../icons/line-vertical";
import styles from "./index.module.scss";

const {
   stepped,
   stepped__title,
   stepped__line,
   stepped__step,
   stepped__content,
   stepped__point,
   stepped__horizontal_line,
} = styles;

function Stepped({ title, steps, className }) {
   return (
      <section className={stepped + " " + className}>
         <h2 className={stepped__title}>{title}</h2>
         <LineVertical className={stepped__line} />
         <Line className={stepped__horizontal_line} />
         {steps.map((step, key) => (
            <div className={stepped__step} key={key}>
               <div className={stepped__content}>{step.content}</div>
               <div className={stepped__point}>{step.point}</div>
            </div>
         ))}
      </section>
   );
}

export default Stepped;
