export default function Telegram(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "20"}
         height={props.height ? props.height : "18"}
         viewBox="0 0 20 18"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.9283 0.80097C18.1742 0.697472 18.4434 0.661776 18.7078 0.697598C18.9722 0.733419 19.2221 0.839448 19.4316 1.00465C19.6411 1.16985 19.8025 1.38818 19.899 1.63692C19.9955 1.88566 20.0235 2.15572 19.9802 2.41898L17.7234 16.1084C17.5044 17.4288 16.0556 18.1861 14.8446 17.5284C13.8316 16.9781 12.327 16.1303 10.9737 15.2456C10.297 14.8028 8.22427 13.3848 8.47901 12.3758C8.69793 11.5131 12.1807 8.27107 14.1709 6.34359C14.952 5.58633 14.5958 5.14949 13.6734 5.84605C11.3817 7.57551 7.70483 10.2055 6.48884 10.9459C5.41614 11.5986 4.8569 11.7101 4.1882 11.5986C2.96823 11.3956 1.83682 11.0812 0.913377 10.6981C-0.33446 10.1806 -0.27376 8.46512 0.912382 7.96558L17.9283 0.80097Z"
            fill="#0070F3"
         />
      </svg>
   );
}
