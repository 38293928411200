export default function Shield(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "64"}
         height={props.height ? props.height : "64"}
         viewBox="0 0 64 64"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <g filter="url(#filter0_i_120_145)">
            <path
               d="M9.42407 18.42C9.42407 16.616 10.8361 15.18 12.5141 14.512C14.5341 13.708 16.7501 12.064 18.1161 8.68199C18.8361 6.89599 20.4241 5.48999 22.3501 5.48999L37.9581 5.48999C39.9241 5.48999 41.5261 6.96199 42.3241 8.75799C43.4661 11.3248 45.4816 13.4034 48.0121 14.624C49.6121 15.404 50.9721 16.9 50.9721 18.678V25.118C50.9721 39.014 46.6261 47.402 35.1661 57.03C34.0494 57.9721 32.6461 58.5075 31.1856 58.5486C29.7251 58.5896 28.2939 58.1339 27.1261 57.256C16.5961 49.308 9.42407 39.894 9.42407 25.12L9.42407 18.42V18.42Z"
               fill="#378AED"
            />
            <path
               d="M9.42407 18.42C9.42407 16.616 10.8361 15.18 12.5141 14.512C14.5341 13.708 16.7501 12.064 18.1161 8.68199C18.8361 6.89599 20.4241 5.48999 22.3501 5.48999L37.9581 5.48999C39.9241 5.48999 41.5261 6.96199 42.3241 8.75799C43.4661 11.3248 45.4816 13.4034 48.0121 14.624C49.6121 15.404 50.9721 16.9 50.9721 18.678V25.118C50.9721 39.014 46.6261 47.402 35.1661 57.03C34.0494 57.9721 32.6461 58.5075 31.1856 58.5486C29.7251 58.5896 28.2939 58.1339 27.1261 57.256C16.5961 49.308 9.42407 39.894 9.42407 25.12L9.42407 18.42V18.42Z"
               fill="url(#paint0_radial_120_145)"
            />
         </g>
         <path
            d="M31.024 58.552L31.024 5.48999L37.958 5.48999C39.924 5.48999 41.526 6.96199 42.324 8.75799C43.4661 11.3248 45.4816 13.4034 48.012 14.624C49.612 15.404 50.972 16.9 50.972 18.678V25.118C50.972 39.014 46.626 47.402 35.166 57.03C34.0059 58.006 32.5401 58.5439 31.024 58.55V58.552Z"
            fill="#3C88F0"
            fillOpacity="0.5"
         />
         <g filter="url(#filter1_i_120_145)">
            <path
               d="M31.024 58.528L31.024 5.48999L38.76 5.48999C40.78 5.48999 42.43 6.96199 43.25 8.75799C44.63 11.788 46.996 13.63 49.098 14.624C50.742 15.404 52.144 16.9 52.144 18.678V25.118C52.144 39.014 47.672 47.402 35.892 57.03C34.5253 58.1421 32.7794 58.6786 31.024 58.526V58.528Z"
               fill="url(#paint1_radial_120_145)"
            />
            <path
               d="M31.024 58.528L31.024 5.48999L38.76 5.48999C40.78 5.48999 42.43 6.96199 43.25 8.75799C44.63 11.788 46.996 13.63 49.098 14.624C50.742 15.404 52.144 16.9 52.144 18.678V25.118C52.144 39.014 47.672 47.402 35.892 57.03C34.5253 58.1421 32.7794 58.6786 31.024 58.526V58.528Z"
               fill="url(#paint2_radial_120_145)"
            />
         </g>
         <g filter="url(#filter2_iii_120_145)">
            <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M22.35 4.08997L39.698 4.08997C42.356 4.08997 44.362 6.00997 45.23 8.15797C46.438 11.15 48.35 12.532 50.05 13.21C52.03 13.996 54.024 15.826 54.024 18.422V25.12C54.024 40.48 46.508 50.264 35.766 58.372C34.4012 59.404 32.7351 59.9591 31.024 59.952C29.3129 59.9591 27.6469 59.404 26.282 58.372C15.54 50.264 8.02002 40.482 8.02002 25.12L8.02002 18.42C8.02002 15.826 10.018 13.996 11.994 13.21C13.698 12.532 15.608 11.15 16.816 8.15797C17.684 6.00797 19.69 4.08997 22.346 4.08997H22.35ZM31.024 57.15C32.1262 57.1576 33.2002 56.8019 34.08 56.138C44.398 48.35 51.224 39.306 51.224 25.118V18.424C51.224 17.406 50.396 16.362 49.016 15.814C46.68 14.884 44.158 12.982 42.634 9.20797C42.058 7.78597 40.894 6.89197 39.698 6.89197L22.348 6.89197C21.154 6.89197 19.988 7.78597 19.414 9.20797C17.89 12.982 15.368 14.884 13.032 15.814C11.652 16.362 10.824 17.406 10.824 18.424L10.824 25.122C10.824 39.308 17.65 48.352 27.968 56.14C28.874 56.822 29.948 57.16 31.024 57.154V57.15Z"
               fill="url(#paint3_linear_120_145)"
            />
         </g>
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.35 4.08997L39.698 4.08997C42.356 4.08997 44.362 6.00997 45.23 8.15797C46.438 11.15 48.35 12.532 50.05 13.21C52.03 13.996 54.024 15.826 54.024 18.422V25.12C54.024 40.48 46.508 50.264 35.766 58.372C34.4012 59.404 32.7351 59.9591 31.024 59.952C29.3129 59.9591 27.6469 59.404 26.282 58.372C15.54 50.264 8.02002 40.482 8.02002 25.12L8.02002 18.42C8.02002 15.826 10.018 13.996 11.994 13.21C13.698 12.532 15.608 11.15 16.816 8.15797C17.684 6.00797 19.69 4.08997 22.346 4.08997H22.35ZM31.024 57.15C32.1262 57.1576 33.2002 56.8019 34.08 56.138C44.398 48.35 51.224 39.306 51.224 25.118V18.424C51.224 17.406 50.396 16.362 49.016 15.814C46.68 14.884 44.158 12.982 42.634 9.20797C42.058 7.78597 40.894 6.89197 39.698 6.89197L22.348 6.89197C21.154 6.89197 19.988 7.78597 19.414 9.20797C17.89 12.982 15.368 14.884 13.032 15.814C11.652 16.362 10.824 17.406 10.824 18.424L10.824 25.122C10.824 39.308 17.65 48.352 27.968 56.14C28.874 56.822 29.948 57.16 31.024 57.154V57.15Z"
            fill="url(#paint4_radial_120_145)"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.35 4.08997L39.698 4.08997C42.356 4.08997 44.362 6.00997 45.23 8.15797C46.438 11.15 48.35 12.532 50.05 13.21C52.03 13.996 54.024 15.826 54.024 18.422V25.12C54.024 40.48 46.508 50.264 35.766 58.372C34.4012 59.404 32.7351 59.9591 31.024 59.952C29.3129 59.9591 27.6469 59.404 26.282 58.372C15.54 50.264 8.02002 40.482 8.02002 25.12L8.02002 18.42C8.02002 15.826 10.018 13.996 11.994 13.21C13.698 12.532 15.608 11.15 16.816 8.15797C17.684 6.00797 19.69 4.08997 22.346 4.08997H22.35ZM31.024 57.15C32.1262 57.1576 33.2002 56.8019 34.08 56.138C44.398 48.35 51.224 39.306 51.224 25.118V18.424C51.224 17.406 50.396 16.362 49.016 15.814C46.68 14.884 44.158 12.982 42.634 9.20797C42.058 7.78597 40.894 6.89197 39.698 6.89197L22.348 6.89197C21.154 6.89197 19.988 7.78597 19.414 9.20797C17.89 12.982 15.368 14.884 13.032 15.814C11.652 16.362 10.824 17.406 10.824 18.424L10.824 25.122C10.824 39.308 17.65 48.352 27.968 56.14C28.874 56.822 29.948 57.16 31.024 57.154V57.15Z"
            fill="url(#paint5_radial_120_145)"
         />
         <defs>
            <filter
               id="filter0_i_120_145"
               x="9.42407"
               y="5.48999"
               width="42.298"
               height="53.8012"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="0.75" dy="0.74" />
               <feGaussianBlur stdDeviation="0.5" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.337255 0 0 0 0 0.713726 0 0 0 1 0" />
               <feBlend mode="normal" in2="shape" result="effect1_innerShadow_120_145" />
            </filter>
            <filter
               id="filter1_i_120_145"
               x="30.624"
               y="5.48999"
               width="21.52"
               height="53.8115"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="-0.4" dy="0.8" />
               <feGaussianBlur stdDeviation="0.375" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.121569 0 0 0 0 0.478431 0 0 0 0 0.843137 0 0 0 1 0" />
               <feBlend mode="normal" in2="shape" result="effect1_innerShadow_120_145" />
            </filter>
            <filter
               id="filter2_iii_120_145"
               x="7.77002"
               y="3.83997"
               width="46.504"
               height="56.2621"
               filterUnits="userSpaceOnUse"
               colorInterpolationFilters="sRGB"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix" />
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="0.25" />
               <feGaussianBlur stdDeviation="0.25" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.635294 0 0 0 0 0.588235 0 0 0 0 0.596078 0 0 0 1 0" />
               <feBlend mode="normal" in2="shape" result="effect1_innerShadow_120_145" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dy="-0.25" />
               <feGaussianBlur stdDeviation="0.25" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.647059 0 0 0 0 0.501961 0 0 0 0 0.745098 0 0 0 1 0" />
               <feBlend mode="normal" in2="effect1_innerShadow_120_145" result="effect2_innerShadow_120_145" />
               <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
               />
               <feOffset dx="-0.25" dy="0.15" />
               <feGaussianBlur stdDeviation="0.15" />
               <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
               <feColorMatrix type="matrix" values="0 0 0 0 0.854902 0 0 0 0 0.85098 0 0 0 0 0.862745 0 0 0 1 0" />
               <feBlend mode="normal" in2="effect2_innerShadow_120_145" result="effect3_innerShadow_120_145" />
            </filter>
            <radialGradient
               id="paint0_radial_120_145"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(38.0281 33.568) rotate(139.976) scale(26.1786 36.665)"
            >
               <stop offset="0.792" stopColor="#3782E8" stopOpacity="0" />
               <stop offset="0.943" stopColor="#286ACA" />
            </radialGradient>
            <radialGradient
               id="paint1_radial_120_145"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(31.024 32.02) scale(20.2384 44.8518)"
            >
               <stop stopColor="#52C9FF" />
               <stop offset="1" stopColor="#59CBFF" />
            </radialGradient>
            <radialGradient
               id="paint2_radial_120_145"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(33.1742 28.7278) rotate(12.048) scale(18.4169 31.6786)"
            >
               <stop offset="0.797" stopColor="#44AAFF" stopOpacity="0" />
               <stop offset="0.966" stopColor="#1B7CE2" />
            </radialGradient>
            <linearGradient
               id="paint3_linear_120_145"
               x1="9.67402"
               y1="35.758"
               x2="44.026"
               y2="35.758"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#B8B3BE" />
               <stop offset="1" stopColor="#BBB3C4" />
            </linearGradient>
            <radialGradient
               id="paint4_radial_120_145"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(16.8582 12.8306) rotate(-126.254) scale(1.68372 10.4745)"
            >
               <stop offset="0.279" stopColor="#B8B1C0" />
               <stop offset="1" stopColor="#B8B1C0" stopOpacity="0" />
            </radialGradient>
            <radialGradient
               id="paint5_radial_120_145"
               cx="0"
               cy="0"
               r="1"
               gradientUnits="userSpaceOnUse"
               gradientTransform="translate(20.844 9.87396) rotate(-126.69) scale(3.975 4.89875)"
            >
               <stop offset="0.36" stopColor="#B8B1C0" />
               <stop offset="1" stopColor="#B8B1C0" stopOpacity="0" />
            </radialGradient>
         </defs>
      </svg>
   );
}
