import classes from "./styles.module.scss";

const {
    underBannerContainer
} = classes;

function UnderBannerContainer ({children, className}) {
    return (
        <div className={underBannerContainer + (className ? " "+className : "")}>
            {
                children
            }
        </div>
    );
}

export default UnderBannerContainer;