import { Link } from "react-router-dom";
import Facebook from "../icons/facebook";
import Instagram from "../icons/instagram";
import Logo from "../icons/logo";
import Telegram from "../icons/telegram";
import Twitter from "../icons/twitter";

import styles from "./index.module.scss";

const {
   map,
   map__footer,
   map__head,
   map__content,
   map__address,
   map__social,
   map__social_icon,
   map__developer,
   map__developer_police,
} = styles;

function Map() {
   return (
      <section className={map}>
         <footer className={map__footer}>
            <div className={map__head}>
               <Logo />
               <span>Онлайн банкинг</span>
            </div>
            <div className={map__content}>
               <address className={map__address}>
                  <p>85310 Будва, Черногория</p>
                  <span>ПН-СБ 10:00 - 21:00</span>
               </address>
               <address className={map__address}>
                  <p>85316 Бечичи, община Будва, Черногория</p>
                  <span>ПН-ВС 9:00 - 22:00</span>
               </address>
               {/* <address className={map__address}>
                  <a href="tel:+38268365853">+382 68-365-853 (Telegram, WhatsApp)</a>
                  <a href="tel:+79014015798">+7 901 401-57-98 (Telegram, WhatsApp)</a>
               </address> */}
            </div>
            <div className={map__social}>
               <a href="http://" target={"_blank"} rel="noreferrer">
                  <Facebook className={map__social_icon} />
               </a>
               <a href="http://" target={"_blank"} rel="noreferrer">
                  <Twitter className={map__social_icon} />
               </a>
               <a href="http://" target={"_blank"} rel="noreferrer">
                  <Telegram className={map__social_icon} />
               </a>
               <a href="http://" target={"_blank"} rel="noreferrer">
                  <Instagram className={map__social_icon} />
               </a>
            </div>
            <div className={map__developer}>
               <Link className={map__developer_police} to={"/privacy-policy"}>
                  Политика конфиденциальности
               </Link>
               <a href="https://www.moosyo.com" target={"_blank"} rel="noreferrer">
                  MoosYo LLC © {new Date().getFullYear()}
               </a>
            </div>
         </footer>
      </section>
   );
}

export default Map;
