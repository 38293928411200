import styles from "./index.module.scss";

const { button__icon } = styles;

function ButtonIcon({ type, className, children, action }) {
   return (
      <button
         type={type ? type : "button"}
         className={className ? button__icon + " " + className : button__icon}
         onClick={action}
      >
         <span>{children ? children : "Кнопка"}</span>
      </button>
   );
}

export default ButtonIcon;
